import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import NodeTask, {
  EdgeData,
  NODE_EDGE_SELECTED,
  NODE_EDGE_UNSELECTED,
} from './NodeTask';

const useEdgeSelected = (
  setTasks: Dispatch<SetStateAction<Array<NodeTask>>>
) => {
  const [edgeSelected, setEdgeSelected] = useState<EdgeData | null>(null);
  const [edgeUnselected, setEdgeUnselected] = useState<EdgeData | null>(null);

  useEffect(() => {
    if (!edgeSelected) return;
    setEdgeSelected(null);

    setTasks((tasks) =>
      tasks.concat({
        type: NODE_EDGE_SELECTED,
        edgeData: edgeSelected,
      })
    );
  }, [edgeSelected, setTasks]);

  useEffect(() => {
    if (!edgeUnselected) return;
    setEdgeUnselected(null);

    setTasks((tasks) =>
      tasks.concat({ type: NODE_EDGE_UNSELECTED, edgeData: edgeUnselected })
    );
  }, [edgeUnselected, setTasks]);

  return { setEdgeSelected, setEdgeUnselected };
};

export default useEdgeSelected;
