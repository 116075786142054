import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Edge } from 'react-flow-renderer';

import delayed from 'utils/delayed';

import NodeTask, { NODE_ADD_EDGE, NODE_REMOVE_EDGE } from './NodeTask';
import useTodoTreeContext from './provider/useTodoTreeContext';

const useEdgeConnected = (
  setTasks: Dispatch<SetStateAction<Array<NodeTask>>>
) => {
  const [edgeConnected, setEdgeConnected] = useState<Edge | null>(null);
  const { addEdge } = useTodoTreeContext();

  useEffect(() => {
    if (!edgeConnected) return;
    setEdgeConnected(null);

    setTasks((tasks) =>
      tasks.concat({
        type: NODE_ADD_EDGE,
        edge: edgeConnected,
      })
    );

    const save = async () => {
      const { source, target } = edgeConnected;

      try {
        await delayed(addEdge(target, source));
      } catch (error) {
        setTasks((tasks) =>
          tasks.concat({
            type: NODE_REMOVE_EDGE,
            edge: edgeConnected,
          })
        );
      }
    };
    save();
  }, [edgeConnected, setTasks, addEdge]);

  return { setEdgeConnected };
};

export default useEdgeConnected;
