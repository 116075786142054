import { FC } from 'react';
import {
  Grid,
  Grow,
  Paper,
  Typography,
  createStyles,
  makeStyles,
  Fade,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import useScreenSize from 'utils/useScreenSize';

import CodeStep from './steps/CodeStep';
import FormStep from './steps/FormStep';

import useRegisterPage from './useRegisterPage';

type StylesProps = {
  isMobile: boolean;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      padding: `${theme.spacing(6)}px 0`,
      borderRadius: '8px',
      border: (props: StylesProps) =>
        props.isMobile ? 'none' : `1px solid rgba(0, 0, 0, 0.16)`,
    },
    title: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.64)',
      marginBottom: theme.spacing(8),
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(6)}px`,
    },
    form: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(6)}px`,
    },
    code: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(5)}px`,
    },
    links: {
      marginTop: theme.spacing(10),
    },
    link: {
      textDecoration: 'none',
    },
  })
);

const RegisterPage = () => {
  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });
  const {
    formStep,
    form,
    setForm,
    formErrors,
    submitForm,
    FormRequest,
    digits,
    setDigits,
    submitCode,
    ConfirmCodeRequest,
    submitError,
  } = useRegisterPage();

  return (
    <Grow in={true}>
      <Grid container>
        <Container className={classes.paper} isMobile={isMobile}>
          <Grid container direction="column">
            <Grid item container justify="center">
              <Typography variant="h6" className={classes.title}>
                <b>Create your account</b>
              </Typography>
            </Grid>

            <Fade
              in={formStep}
              unmountOnExit
              timeout={{ exit: 0, enter: 200, appear: 200 }}
            >
              <div className={classes.form}>
                <FormStep
                  form={form}
                  setForm={setForm}
                  formErrors={formErrors}
                  onSubmit={submitForm}
                  error={submitError}
                  loading={FormRequest.loading}
                ></FormStep>
              </div>
            </Fade>

            <Fade
              in={!formStep}
              unmountOnExit
              timeout={{ exit: 0, enter: 200, appear: 200 }}
            >
              <div className={classes.code}>
                <CodeStep
                  email={form.email}
                  digits={digits}
                  setDigits={setDigits}
                  loading={ConfirmCodeRequest.loading}
                  submit={submitCode}
                  error={submitError}
                ></CodeStep>
              </div>
            </Fade>

            <Grid item container justify="center" className={classes.links}>
              <Link to={Routes.AUTH.LOGIN.ROOT} className={classes.link}>
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  Have an account ? <b>Log in</b>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grow>
  );
};

type ContainerProps = {
  isMobile: boolean;
  className: string;
};
const Container: FC<ContainerProps> = ({ children, isMobile, className }) => {
  if (isMobile) return <div className={className}>{children}</div>;

  return (
    <Paper elevation={4} className={className}>
      {children}
    </Paper>
  );
};

export default RegisterPage;
