import { FC, useMemo } from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: 'rgba(0, 0, 0, 0.48)',
    },
  })
);

type Props = {
  timestamp: number;
};
const CompleteTodoDate: FC<Props> = ({ timestamp }) => {
  const classes = useStyles();
  const dateObj = useMemo(() => new Date(timestamp), [timestamp]);
  const date = dateObj.toLocaleDateString('en-GB');
  const time = dateObj.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <Typography
      variant="body2"
      className={classes.text}
    >{`Completed on ${date}, ${time}`}</Typography>
  );
};

export default CompleteTodoDate;
