import { Switch, Route } from 'react-router-dom';

import Routes from 'config/Routes';

import TodoMenuSection from './TodoMenuSection';

const TodoSection = () => {
  return (
    <Switch>
      <Route path={Routes.APP.TODO.MENU.ROOT}>
        <TodoMenuSection></TodoMenuSection>
      </Route>
    </Switch>
  );
};

export default TodoSection;
