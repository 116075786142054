import { FunctionComponent } from 'react';
import { AddBoxOutlined } from '@material-ui/icons';

import ItemButton from 'components/buttons/ItemButton';

type Props = {
  onClick: () => void;
  disabled: boolean;
};
const AddTaskItemButton: FunctionComponent<Props> = ({ onClick, disabled }) => {
  return (
    <ItemButton
      label="Add Task"
      icon={<AddBoxOutlined></AddBoxOutlined>}
      disabled={disabled}
      onClick={onClick}
    ></ItemButton>
  );
};

export default AddTaskItemButton;
