import TodoParentChild from 'domain/todo/types/TodoParentChild';

import api from 'services/api';

const addEdgeEmailService = async (
  todoID: string,
  parentID: string
): Promise<TodoParentChild> => {
  const todoParentChild = await api.todo.addEdge(todoID, parentID);
  return todoParentChild;
};

export default addEdgeEmailService;
