import Todo from 'domain/todo/types/Todo';
import { Edge, Position } from 'react-flow-renderer';

export const NODE_UPDATED = 'node_updated';
export const NODE_CREATED = 'node_created';
export const NODE_ADD_MENU_OPENED = 'node_add_menu_opened';
export const NODE_ADD_MENU_CLOSED = 'node_add_menu_closed';
export const NODE_ADD_EDGE = 'node_add_edge';
export const NODE_REMOVE_EDGE = 'node_remove_edge';
export const NODE_EDGE_SELECTED = 'node_edge_selected';
export const NODE_EDGE_UNSELECTED = 'node_edge_unselected';
export const NODE_DELETED = 'node_deleted';

export type EdgeData = {
  source: string;
  target: string;

  sourceX: number;
  sourceY: number;

  targetX: number;
  targetY: number;

  sourcePosition: Position | undefined;
  targetPosition: Position | undefined;
};

type NodeTask = {
  type:
    | typeof NODE_UPDATED
    | typeof NODE_CREATED
    | typeof NODE_ADD_MENU_OPENED
    | typeof NODE_ADD_MENU_CLOSED
    | typeof NODE_ADD_EDGE
    | typeof NODE_REMOVE_EDGE
    | typeof NODE_EDGE_SELECTED
    | typeof NODE_EDGE_UNSELECTED
    | typeof NODE_DELETED;
  todo?: Todo;
  edge?: Edge;
  edgeData?: EdgeData;
};

export default NodeTask;
