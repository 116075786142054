import { Button, Paper, makeStyles, createStyles } from '@material-ui/core';

import ProfileMenu from './ProfileMenu';

import useProfile from './useProfile';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      height: theme.spacing(4),
      minWidth: theme.spacing(4),
      borderRadius: theme.spacing(4),
    },
    button: {
      height: 'inherit',
      minWidth: 'inherit',
      borderRadius: 'inherit',
      color: theme.palette.grey[100],
      fontFamily: 'Sriracha, cursive',
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

const ProfileAvatar = () => {
  const { name, text, email } = useProfile();
  const classes = useStyles();

  return (
    <ProfileMenu
      email={email}
      name={name}
      renderButton={(onClick) => (
        <Paper className={classes.root} elevation={2}>
          <Button className={classes.button} onClick={onClick}>
            <b>{text}</b>
          </Button>
        </Paper>
      )}
    ></ProfileMenu>
  );
};

export default ProfileAvatar;
