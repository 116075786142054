import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

import HorizontalList from './HorizontalList';
import EmptyList from './EmptyList';

import useTodoRootsList from './useTodoRootsList';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: 'Sriracha, cursive',
      color: theme.palette.primary.dark,
    },
    questionButton: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.dark,
    },
  })
);

const TodoRootsList = () => {
  const classes = useStyles();
  const { roots } = useTodoRootsList();

  const isListEmpty = roots.length === 0;

  return (
    <Grid container direction="column">
      <Grid item container alignItems="center">
        <Typography variant="h5" className={classes.title}>
          <b>ToDo Roots</b>
        </Typography>
      </Grid>

      <Grid item container style={{ marginTop: 8 }}>
        {isListEmpty && <EmptyList></EmptyList>}
        {!isListEmpty && <HorizontalList roots={roots}></HorizontalList>}
      </Grid>
    </Grid>
  );
};

export default TodoRootsList;
