import { FunctionComponent } from 'react';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import { TextTransformProperty } from 'csstype';

type StyleProps = {
  textTransform: TextTransformProperty;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      textTransform: (props: StyleProps) => props.textTransform,
    },
  })
);

type Props = {
  onClick?: () => void;
  startIcon?: JSX.Element;
  textTransform?: TextTransformProperty;
};
const FlatButton: FunctionComponent<Props> = ({
  children,
  onClick = () => {},
  startIcon,
  textTransform = 'uppercase',
}) => {
  const classes = useStyles({ textTransform });

  return (
    <Button className={classes.root} onClick={onClick} startIcon={startIcon}>
      {children}
    </Button>
  );
};

export default FlatButton;
