import useAuthentication from 'pages/providers/authentication/useAuthentication';

const useProfile = () => {
  const { user } = useAuthentication();

  const firstLetter = (word: string) => word[0];

  return {
    text: user ? firstLetter(user.name) : '',
    email: user ? user.email : '',
    name: user ? user.name : '',
  };
};

export default useProfile;
