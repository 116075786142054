import { Fragment, FunctionComponent, useContext } from 'react';
import {
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { NodeProps, Handle, Position } from 'react-flow-renderer';

import Todo from 'domain/todo/types/Todo';

import FocusTextField from 'components/textfield/FocusTextField';
import FlatButton from 'components/buttons/FlatButton';

import { DrawContext } from '../../Draw';
import PaperCard from '../PaperCard';

import useNodeAddCard from './useNodeAddCard';

const useStyles = makeStyles((theme) =>
  createStyles({
    handle: {
      width: 8,
      height: 8,
      backgroundColor: theme.palette.primary.light,
      border: '1px solid #fff',
    },
  })
);

const NodeAddCard: FunctionComponent<NodeProps<Todo>> = ({
  selected,
  isDragging,
  data,
}) => {
  const classes = useStyles();
  const { closeAddCard } = useContext(DrawContext);
  const { title, setTitle, onSubmit, inputRef, loading } = useNodeAddCard(data);

  const onClose = () => closeAddCard(data);

  return (
    <PaperCard dragging={isDragging} selected={selected}>
      <Handle
        type="source"
        position={Position.Left}
        className={classes.handle}
      ></Handle>
      <Handle
        type="target"
        position={Position.Right}
        className={classes.handle}
      ></Handle>

      <Grid container direction="column">
        <Grid item container>
          <FocusTextField
            value={title}
            onChange={setTitle}
            autoFocus
            inputRef={inputRef}
          ></FocusTextField>
        </Grid>

        <Grid item container justify="flex-end" alignItems="center">
          <Grid item>
            <FlatButton
              onClick={onSubmit}
              startIcon={
                loading ? (
                  <CircularProgress size={20}></CircularProgress>
                ) : (
                  <Fragment></Fragment>
                )
              }
            >
              <b>Add Todo</b>
            </FlatButton>
          </Grid>

          <Grid item style={{ marginLeft: 8 }}>
            <IconButton size="small" onClick={onClose}>
              <Close></Close>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </PaperCard>
  );
};

export default NodeAddCard;
