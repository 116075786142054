import { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';

import ItemButton from 'components/buttons/ItemButton';

import useNodeCompleteButton from './useNodeCompleteButton';

type Props = {
  todo: Todo;
  disabled?: boolean;
};
const NodeCompleteButton: FunctionComponent<Props> = ({
  todo,
  disabled = false,
}) => {
  const { loading, onSubmit } = useNodeCompleteButton(todo);
  const { completed } = todo;

  return (
    <ItemButton
      label={completed ? 'Mark as incomplete' : 'Mark as complete'}
      onClick={onSubmit}
      disabled={disabled}
      icon={
        loading ? (
          <CircularProgress
            size={18}
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          ></CircularProgress>
        ) : completed ? (
          <CheckCircle></CheckCircle>
        ) : (
          <CheckCircleOutline></CheckCircleOutline>
        )
      }
    ></ItemButton>
  );
};

export default NodeCompleteButton;
