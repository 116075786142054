import { useEffect, useState } from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import delayed from 'utils/delayed';

const useRequest = <T>(
  func: () => Promise<T>,
  onDone?: (args: T) => void,
  delay = 0
) => {
  const [status, setStatus] = useState<LoadStatus>();

  useEffect(() => {
    if (status !== SUBMIT) return;
    setStatus(LOADING);

    const req = async () => {
      try {
        const t = await delayed(func(), delay);
        setStatus(DONE);
        onDone?.(t);
      } catch (error) {
        setStatus(ERROR);
        throw error;
      }
    };
    req();
  }, [status, func, onDone, delay]);

  const submit = () => {
    if (status === LOADING) return;
    if (status === SUBMIT) return;
    setStatus(SUBMIT);
  };

  return {
    submit,
    done: status === DONE,
    loading: status === LOADING,
    failed: status === ERROR,
  };
};

export default useRequest;
