import { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: 'rgba(0, 0, 0, 0.48)',
    },
  })
);

type Props = {
  completed?: boolean;
};
const EmptyTasksWarning: FunctionComponent<Props> = ({ completed }) => {
  const classes = useStyles();

  return completed ? (
    <Grid container direction="column">
      <Grid item container>
        <Typography variant="body2" className={classes.text}>
          This ToDo has been <b>completed</b>
        </Typography>
      </Grid>

      <Grid item container style={{ marginTop: 8 }}>
        <Typography variant="body2" className={classes.text}>
          You <b>can't</b> add more tasks
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Typography variant="body2" className={classes.text}>
      You can <b>break</b> this ToDo in <b>smaller</b> ToDo's
    </Typography>
  );
};

export default EmptyTasksWarning;
