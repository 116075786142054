import TodoContentErrors from './TodoContentErrors';

class TodoContentValidationError {
  errors: TodoContentErrors;

  constructor(errors: TodoContentErrors) {
    this.errors = errors;
  }
}

export default TodoContentValidationError;
