import { FC, Fragment, useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Fade,
} from '@material-ui/core';

import Digit from './Digit';

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);

type Props = {
  digits: Array<string>;
  setDigits: (digits: Array<string>) => void;
  loading: boolean;
  submit: () => void;
  errorMessage: string;
};
const CodeInput: FC<Props> = ({
  digits,
  setDigits,
  loading,
  submit,
  errorMessage,
}) => {
  const classes = useStyles();

  const inputs = Array(digits.length)
    .fill('')
    .map((_, index) => index);

  const _focusInput = (id: number) => {
    const digit = document.getElementById(`digit_${id}`);
    if (!digit) return;

    const input = digit.getElementsByTagName('input')[0];
    input.focus();
  };

  useEffect(() => {
    _focusInput(0);
  }, []);

  const _onChange = (id: number) => (newValue: string) => {
    const newValues = [...digits];

    if (!newValue) newValues[id] = '';
    else newValues[id] = isNaN(parseInt(newValue)) ? newValues[id] : newValue;

    setDigits(newValues);

    if (!newValue || isNaN(parseInt(newValue))) return;

    if (id + 1 < inputs.length) _focusInput(id + 1);
    else submit();
  };

  return (
    <Grid container direction="column">
      <Grid item container spacing={1}>
        <Fade
          in={!loading}
          unmountOnExit
          timeout={{ exit: 0, enter: 200, appear: 200 }}
        >
          <Fragment>
            {inputs.map((id) => {
              return (
                <Grid item xs={2} key={id}>
                  <Digit
                    id={id}
                    value={digits[id]}
                    onChange={_onChange(id)}
                  ></Digit>
                </Grid>
              );
            })}
          </Fragment>
        </Fade>

        <Fade
          in={loading}
          unmountOnExit
          timeout={{ exit: 0, enter: 200, appear: 200 }}
        >
          <Grid item container justify="center">
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              Confirming code...
            </Typography>
          </Grid>
        </Fade>
      </Grid>

      <Fade in={!!errorMessage} timeout={{ exit: 0, enter: 200, appear: 200 }}>
        <Grid item container style={{ marginTop: 24 }} justify="flex-end">
          <Typography variant="body2" className={classes.error}>
            <b>
              <i>{errorMessage}</i>
            </b>
          </Typography>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default CodeInput;
