import { FunctionComponent, useCallback, useState } from 'react';
import {
  Collapse,
  Grid,
  Grow,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import TodoInputChild from 'components/todo/input/child/TodoInputChild';

import EmptyTasksWarning from './EmptyTasksWarning';
import TasksList from './TasksList';

const useStyles = makeStyles((theme) =>
  createStyles({
    add: {
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
    },
    addButton: {
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    addField: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(1),
    },
  })
);

type Props = {
  parent: Todo;
  tasks: Array<Todo>;
  onChildCreated: (todo: Todo) => void;
  completed?: boolean;
  createTodoChild: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
};
const TasksForm: FunctionComponent<Props> = ({
  parent,
  tasks,
  onChildCreated,
  completed,
  createTodoChild,
}) => {
  const [showAddField, setShowAddField] = useState(false);
  const classes = useStyles();
  const hasAnyTask = tasks.length > 0;

  const _onAddButtonClick = () => setShowAddField(true);

  const _onClose = useCallback(() => setShowAddField(false), []);

  const _onChildCreated = useCallback(
    (todo: Todo) => {
      setShowAddField(false);
      onChildCreated(todo);
    },
    [onChildCreated]
  );

  return (
    <Grid container direction="column">
      <Grid item container alignItems="center" className={classes.header}>
        <Grid item>
          <Typography variant="body1">Tasks</Typography>
        </Grid>

        <Grid item className={classes.add}>
          <Grow in={!showAddField && !completed} unmountOnExit>
            <IconButton
              className={classes.addButton}
              onClick={_onAddButtonClick}
            >
              <Add></Add>
            </IconButton>
          </Grow>
        </Grid>
      </Grid>

      <Collapse in={showAddField} unmountOnExit timeout={50}>
        <Grid item container className={classes.addField}>
          <TodoInputChild
            parent={parent}
            onClose={_onClose}
            onTodoCreated={_onChildCreated}
            createTodoChild={createTodoChild}
          ></TodoInputChild>
        </Grid>
      </Collapse>

      <Grid item container>
        {!hasAnyTask && !showAddField && (
          <EmptyTasksWarning completed={completed}></EmptyTasksWarning>
        )}
        {hasAnyTask && <TasksList tasks={tasks}></TasksList>}
      </Grid>
    </Grid>
  );
};

export default TasksForm;
