import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import RaisedButton from 'components/buttons/RaisedButton';

const DashboardButton = () => {
  return (
    <Link to={Routes.APP.ROOT} style={{ textDecoration: 'none' }}>
      <RaisedButton>GO TO DASHBOARD</RaisedButton>
    </Link>
  );
};

export default DashboardButton;
