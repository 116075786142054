import {
  IsNotRootError,
  NotFoundError,
  ParentCompletedError,
  ParentNotFoundError,
} from 'domain/errors';
import TodoParentChild from 'domain/todo/types/TodoParentChild';
import AnonymousStorage, { ITodo, mapITodoToTodo } from './AnonymousStorage';

const addEdgeAnonymousService =
  (db: AnonymousStorage) =>
  async (todoID: string, parentID: string): Promise<TodoParentChild> => {
    const { iParent, iTodo } = await db.ref.transaction(
      'readwrite',
      db.todos,
      async () => {
        const iTodo = await db.todos.get(parseInt(todoID));
        if (!iTodo) throw new NotFoundError();

        const iParent = await db.todos.get(parseInt(parentID));
        if (!iParent) throw new ParentNotFoundError();

        if (iTodo.parentID) throw new IsNotRootError();
        if (iParent.completed) throw new ParentCompletedError();

        const updatedITodo: ITodo = {
          ...iTodo,
          parentID: iParent.id,
        };

        const updatedIParent: ITodo = {
          ...iParent,
          childrenCount: (iParent.childrenCount || 0) + 1,
          completedChildren:
            (iParent.completedChildren || 0) + (iTodo.completed ? 1 : 0),
        };

        await db.todos.put(updatedITodo, parseInt(todoID));
        await db.todos.put(updatedIParent, parseInt(parentID));

        return {
          iTodo: updatedITodo,
          iParent: updatedIParent,
        };
      }
    );

    return {
      child: mapITodoToTodo(iTodo),
      parent: mapITodoToTodo(iParent),
    };
  };

export default addEdgeAnonymousService;
