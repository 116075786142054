import { createMuiTheme } from '@material-ui/core/styles';

const MainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021296',
      light: '#533dc8',
      dark: '#000067',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Inika, serif',
  },
});

export default MainTheme;
