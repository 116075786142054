import { useEffect, useState } from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';

import useTodoTreeContext from '../../provider/useTodoTreeContext';

const useNodeCompleteButton = (todo: Todo) => {
  const { completeTodo } = useTodoTreeContext();
  const [status, setStatus] = useState<LoadStatus | null>(null);

  useEffect(() => {
    if (status !== SUBMIT) return;
    setStatus(LOADING);

    const complete = async () => {
      try {
        await completeTodo(todo);

        setStatus(DONE);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    complete();
  }, [status, completeTodo, todo]);

  const onSubmit = () => {
    if (status === LOADING || status === SUBMIT) return;
    setStatus(SUBMIT);
  };

  return {
    loading: status === LOADING,
    onSubmit,
  };
};

export default useNodeCompleteButton;
