import { useCallback, useState } from 'react';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import areTodoContentsEqualUseCase from 'domain/todo/usecases/areTodoContentsEqualUseCase';

import useTodoForm from './useTodoForm';

const useTodoFormEdit = (
  initialTodo: Todo,
  editTodo: (todoID: string) => (content: TodoContent) => Promise<Todo>
) => {
  const [todoRoot, setTodoRoot] = useState<Todo>(initialTodo);

  const _onTodoSaved = useCallback((todo: Todo) => {
    setTodoRoot(todo);
  }, []);

  const _saveTodo = useCallback(
    async (content: TodoContent) => {
      const id = initialTodo.id;
      const editedTodo = await editTodo(id)(content);

      return editedTodo;
    },
    [editTodo, initialTodo.id]
  );

  const { content, setContent, errors, onSubmit } = useTodoForm({
    initialContent: initialTodo.content,
    onTodoSaved: _onTodoSaved,
    saveTodo: _saveTodo,
  });

  return {
    content,
    setContent,
    errors,
    onSubmit,
    hasChanged: !areTodoContentsEqualUseCase(todoRoot.content, content),
  };
};

export default useTodoFormEdit;
