import {
  Grid,
  makeStyles,
  Typography,
  createStyles,
  Grow,
} from '@material-ui/core';
import { Save, Block } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import FlatButton from 'components/buttons/FlatButton';
import PaperTextField from 'components/textfield/PaperTextField';

import useLoginAnonymous from './useLoginAnonymous';

const useStyles = makeStyles((theme) =>
  createStyles({
    titleSpace: {
      marginBottom: theme.spacing(8),
      color: 'rgca(0, 0, 0, 0.64)',
    },
    icon: {
      marginRight: theme.spacing(2),
      fontSize: theme.spacing(3.5),
    },
    warning: {
      marginBottom: theme.spacing(8),
      position: 'relative',
    },
    goBackLink: {
      color: 'rgba(0, 0, 0, 0.64)',
      marginTop: theme.spacing(8),
    },
    block: {
      fontSize: 48,
      color: theme.palette.primary.dark,
      position: 'absolute',
      marginTop: -8,
    },
    bold: {
      color: theme.palette.primary.dark,
    },
  })
);

const LoginAnonymous = () => {
  const classes = useStyles();
  const { canLogin, name, setName, submit } = useLoginAnonymous();

  return (
    <Grow in={true}>
      <Grid container direction="column">
        <Grid
          item
          container
          className={classes.titleSpace}
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <i
              className={`fas fa-user-secret ${classes.icon} ${classes.bold}`}
            ></i>
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              <b className={classes.bold}>Anonymous login</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column" className={classes.warning}>
          <Grid item container justify="center">
            <Save style={{ fontSize: 32, color: 'rgba(0, 0, 0, 0.40)' }}></Save>
            <Block className={classes.block}></Block>
          </Grid>

          <Grid item container justify="center" style={{ marginTop: 12 }}>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              All your <b className={classes.bold}>data</b> will be{' '}
              <b className={classes.bold}>deleted</b> when you{' '}
              <b className={classes.bold}>logout</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column" alignItems="center">
          <Grid item container justify="center" style={{ width: '80%' }}>
            <PaperTextField
              value={name}
              onChange={(name) => setName(name)}
              placeholder="How can we call you ?"
              autoFocus
            ></PaperTextField>
          </Grid>

          <Grow in={canLogin}>
            <Grid item container justify="center" style={{ marginTop: 16 }}>
              <FlatButton onClick={submit}>
                <b>Log in</b>
              </FlatButton>
            </Grid>
          </Grow>
        </Grid>

        <Grid item container justify="center">
          <Link className={classes.goBackLink} to={Routes.AUTH.LOGIN.ROOT}>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              More authentication options
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grow>
  );
};

export default LoginAnonymous;
