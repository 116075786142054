import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import AnonymousStorage from './AnonymousStorage';

const editTodoAnonymousService = (db: AnonymousStorage) => (
  id: string
) => async (todoContent: TodoContent): Promise<Todo> => {
  await db.todos.update(parseInt(id), {
    content: todoContent,
  });

  return {
    id,
    content: todoContent,
  };
};

export default editTodoAnonymousService;
