import {
  useState,
  useEffect,
  createContext,
  FunctionComponent,
  MutableRefObject,
} from 'react';
import ReactFlow, {
  useStoreState,
  useStoreActions,
  Background,
  BackgroundVariant,
  Connection,
  Edge,
  EdgeTypesType,
  FlowElement,
  Node,
  NodeTypesType,
  Position,
} from 'react-flow-renderer';
import dagre from 'dagre';

import Todo from 'domain/todo/types/Todo';

import NodeCard from './node/NodeCard';
import NodeAddCard from './node/add/NodeAddCard';

import TodoEdge from './edge/TodoEdge';

import { Graph, Node as GraphNode } from './Graph';
import NodeTask, {
  EdgeData,
  NODE_ADD_EDGE,
  NODE_ADD_MENU_CLOSED,
  NODE_ADD_MENU_OPENED,
  NODE_CREATED,
  NODE_DELETED,
  NODE_EDGE_SELECTED,
  NODE_EDGE_UNSELECTED,
  NODE_REMOVE_EDGE,
  NODE_UPDATED,
} from './NodeTask';

import useEdgeConnected from './useEdgeConnected';
import useEdgeSelected from './useEdgeSelected';

import NodeEdgeSelected from './node/edge_selected/NodeEdgeSelected';
import { buildEdge } from './utils';

type DrawContextType = {
  openAddCard: (todo: Todo) => void;
  closeAddCard: (todo: Todo) => void;

  isTaskOpened: (todo: Todo) => boolean;

  selectEdge: (edge: EdgeData) => void;
  unselectEdge: (edge: EdgeData) => void;

  started: boolean;
};
export const DrawContext = createContext<DrawContextType>({
  openAddCard: () => {},
  closeAddCard: () => {},

  isTaskOpened: () => false,

  selectEdge: () => {},
  unselectEdge: () => {},

  started: false,
});

const nodeTypes: NodeTypesType = {
  todoNode: NodeCard,
  addNode: NodeAddCard,
  edgeSelectedNode: NodeEdgeSelected,
};

const edgeTypes: EdgeTypesType = {
  todoEdge: TodoEdge,
};

const getLayoutedElements = (
  nodes: Array<Node>,
  edges: Array<Edge>
): Array<FlowElement> => {
  const dx = 32;
  const dy = 32;
  const elements: Array<FlowElement> = [];

  const parent: { [key: string]: string } = {};
  const processed: { [key: string]: boolean } = {};
  const mapRankToGraph: { [key: string]: dagre.graphlib.Graph } = {};
  const nodesInsideRank: { [key: string]: Array<Node> } = {};
  const size: { [key: string]: number } = {};

  const rank = (id: string): string => {
    if (parent[id] === id) return id;
    parent[id] = rank(parent[id]);
    return parent[id];
  };
  const merge = (x: string, y: string) => {
    x = rank(x);
    y = rank(y);
    if (size[x] < size[y]) [x, y] = [y, x];
    size[x] += size[y];
    parent[y] = x;
  };

  for (const node of nodes) {
    parent[node.id] = node.id;
    size[node.id] = 1;
  }

  for (const edge of edges) {
    const { source, target } = edge;
    merge(source, target);
  }

  for (const node of nodes) {
    if (processed[rank(node.id)]) continue;

    processed[rank(node.id)] = true;

    const G = new dagre.graphlib.Graph();
    G.setDefaultEdgeLabel(() => ({}));
    G.setGraph({ rankdir: 'RL', ranksep: 100 });

    mapRankToGraph[rank(node.id)] = G;
  }

  for (const node of nodes) {
    const G = mapRankToGraph[rank(node.id)];

    G.setNode(node.id, {
      height: node.__rf.height,
      width: node.__rf.width,
    });
  }

  for (const edge of edges) {
    const G = mapRankToGraph[rank(edge.source)];

    G.setEdge(edge.source, edge.target);
  }

  for (const G of Object.values(mapRankToGraph)) dagre.layout(G);

  for (const node of nodes) {
    if (!nodesInsideRank[rank(node.id)]) nodesInsideRank[rank(node.id)] = [];

    nodesInsideRank[rank(node.id)].push(node);
  }

  const ranks = nodes
    .filter((node) => rank(node.id) === node.id)
    .map((node) => rank(node.id));

  for (const edge of edges) elements.push(edge);

  let deltaY = 0;
  const spaceBetween = 56;

  for (const rankID of ranks) {
    const G = mapRankToGraph[rankID];
    const subNodes = nodesInsideRank[rankID];

    for (const node of subNodes) {
      const nodeWithPosition = G.node(node.id);

      elements.push({
        ...node,
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        position: {
          ...node.position,
          // unfortunately we need this little hack to pass a slighltiy different position
          // to notify react flow about the change. More over we are shifting the dagre node position
          // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
          x:
            dx +
            nodeWithPosition.x -
            node.__rf.width / 2 +
            Math.random() / 1000,
          y: dy + deltaY + nodeWithPosition.y - node.__rf.height / 2,
        },
      });
    }

    deltaY += G.graph().height || 0;
    deltaY += spaceBetween;
  }

  return elements;
};

const buildElementsFromGraph = (graph: Graph<Todo>): Array<FlowElement> => {
  const elements: Array<FlowElement<Todo>> = [];

  const dfs = (node: GraphNode<Todo>) => {
    elements.push({
      id: node.data.id,
      type: 'todoNode',
      data: node.data,
      position: { x: 0, y: 0 },
    });

    for (const child of node.children) {
      dfs(child);

      elements.push(buildEdge(node.data.id, child.data.id));
    }
  };

  for (const root of graph.roots) dfs(root);

  return elements;
};

type Props = {
  graph: Graph<Todo>;
  addedTodo: Todo | null;
  resetAddedTodo: () => void;
  updatedTodo: Todo | null;
  resetUpdatedTodo: () => void;
  deletedTodo: Todo | null;
  resetDeletedTodo: () => void;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};
const Draw: FunctionComponent<Props> = ({
  graph,
  addedTodo,
  resetAddedTodo,
  updatedTodo,
  resetUpdatedTodo,
  deletedTodo,
  resetDeletedTodo,
  containerRef,
}) => {
  const [elements] = useState<Array<FlowElement>>(
    buildElementsFromGraph(graph)
  );
  const setElements = useStoreActions((actions) => actions.setElements);
  const [started, setStarted] = useState(false);
  const [todoTask, setTodoTask] = useState<Todo | null>(null);
  const [todoTaskClosed, setTodoTaskClosed] = useState<Todo | null>(null);
  const [openedTodoTasks, setOpenedTodoTasks] = useState<Array<Todo>>([]);

  const [tasks, setTasks] = useState<Array<NodeTask>>([]);
  const [taskProcessed, setTaskProcessed] = useState(false);

  const { setEdgeConnected } = useEdgeConnected(setTasks);
  const { setEdgeSelected, setEdgeUnselected } = useEdgeSelected(setTasks);

  const nodes = useStoreState((state) => state.nodes);
  const edges = useStoreState((state) => state.edges);

  const openAddCard = (todo: Todo) => setTodoTask(todo);
  const closeAddCard = (todo: Todo) => setTodoTaskClosed(todo);
  const isTaskOpened = (todo: Todo) =>
    !!openedTodoTasks.find((task) => task.id === todo.id);
  const selectEdge = (edge: EdgeData) => setEdgeSelected(edge);
  const unselectEdge = (edge: EdgeData) => setEdgeUnselected(edge);

  const _addNodeID = (id: string) => `add_node_${id}`;
  const _addNodeEdgeID = (id: string) => `add_node_edge_${id}`;
  const _edgeSelectedNodeID = (source: string, target: string) =>
    `edge_selected_node_${source}${target}`;

  useEffect(() => {
    if (started) return;

    const hasDimensionsSet = (node: Node) =>
      node.__rf !== null &&
      node.__rf.width !== null &&
      node.__rf.height !== null;

    if (nodes.length > 0 && nodes.every(hasDimensionsSet)) {
      setStarted(true);
      const correctedElements = getLayoutedElements(nodes, edges);
      setElements(correctedElements);
    }
  }, [started, nodes, edges, setElements]);

  useEffect(() => {
    if (!addedTodo) return;
    resetAddedTodo();

    setTasks((tasks) =>
      tasks.concat({
        type: NODE_CREATED,
        todo: addedTodo,
      })
    );
  }, [addedTodo, resetAddedTodo]);

  useEffect(() => {
    if (!updatedTodo) return;
    resetUpdatedTodo();

    setTasks((tasks) =>
      tasks.concat({ type: NODE_UPDATED, todo: updatedTodo })
    );
  }, [updatedTodo, resetUpdatedTodo]);

  useEffect(() => {
    if (!deletedTodo) return;
    resetDeletedTodo();

    setTasks((tasks) =>
      tasks.concat({ type: NODE_DELETED, todo: deletedTodo })
    );
  }, [deletedTodo, resetDeletedTodo]);

  useEffect(() => {
    if (!todoTask) return;
    setTodoTask(null);

    setTasks((tasks) =>
      tasks.concat({ type: NODE_ADD_MENU_OPENED, todo: todoTask })
    );
  }, [todoTask]);

  useEffect(() => {
    if (!todoTaskClosed) return;
    setTodoTaskClosed(null);

    setTasks((tasks) =>
      tasks.concat({ type: NODE_ADD_MENU_CLOSED, todo: todoTaskClosed })
    );
  }, [todoTaskClosed]);

  useEffect(() => {
    if (!tasks.length) return;
    if (taskProcessed) return;
    setTaskProcessed(true);

    const task = tasks[0];
    const todo = task.todo!;

    const _updated = () => {
      const oldNode = nodes.find((node) => node.id === todo.id)!;
      const oldTodo = oldNode.data as Todo;

      const _nodes = () =>
        nodes.map((node) =>
          node.id === todo.id
            ? {
                ...node,
                data: todo,
              }
            : node
        );

      const _edges = () => {
        if (oldTodo.parentID && !todo.parentID) {
          const edgeToRemove = buildEdge(oldTodo.parentID, todo.id);
          return edges.filter((edge) => edge.id !== edgeToRemove.id);
        }

        return edges;
      };

      setElements([..._nodes(), ..._edges()]);
    };

    const _created = () => {
      const _newTodo = (x: number, y: number) => ({
        id: todo.id,
        type: 'todoNode',
        position: { x, y },
        data: todo,
      });

      setOpenedTodoTasks((tasks) =>
        tasks.filter((task) => task.id !== todo.parentID)
      );

      if (todo.parentID) {
        const parentID = todo.parentID;
        const nodeID = _addNodeID(parentID);
        const edgeID = _addNodeEdgeID(parentID);

        const addNode = nodes.find((node) => node.id === nodeID);
        const newEdge = buildEdge(parentID, todo.id);

        if (addNode) {
          const x = addNode!.__rf.position.x;
          const y = addNode!.__rf.position.y;

          setElements([
            ...nodes.map((node) =>
              node.id === nodeID ? _newTodo(x, y) : node
            ),
            ...edges.map((edge) => (edge.id === edgeID ? newEdge : edge)),
          ]);
        } else {
          const parentNode = nodes.find((node) => node.id === parentID);

          const x = parentNode!.__rf.position.x;
          const y = parentNode!.__rf.position.y + parentNode!.__rf.height + 40;

          setElements([...nodes, ...edges, _newTodo(x, y), newEdge]);
        }
      } else {
        const { width } = containerRef.current!.getBoundingClientRect();
        setElements([...nodes, ...edges, _newTodo(width - 290 - 16, 16)]);
      }
    };

    const _addMenuOpened = () => {
      setOpenedTodoTasks((tasks) => tasks.concat(todo));

      const parentID = todo.id;
      const parentNode = nodes.find((element) => element.id === parentID);
      const x = parentNode!.__rf.position.x;
      const y = parentNode!.__rf.position.y + parentNode!.__rf.height + 40;
      const nodeID = _addNodeID(parentID);
      const edgeID = _addNodeEdgeID(parentID);

      setElements([
        ...nodes,
        ...edges,
        {
          id: edgeID,
          source: parentID,
          target: nodeID,
        },
        {
          id: nodeID,
          type: 'addNode',
          position: { x, y },
          data: todo,
        },
      ]);
    };

    const _addMenuClosed = () => {
      setOpenedTodoTasks((tasks) =>
        tasks.filter((task) => task.id !== todo.id)
      );

      const parentID = todo.id;
      const nodeID = _addNodeID(parentID);
      const edgeID = _addNodeEdgeID(parentID);

      setElements([
        ...nodes.filter((node) => node.id !== nodeID),
        ...edges.filter((edge) => edge.id !== edgeID),
      ]);
    };

    const _addEdge = () => {
      setElements([...nodes, ...edges, task.edge!]);
    };

    const _removeEdge = () => {
      setElements([
        ...nodes,
        ...edges.filter((edge) => edge.id !== task.edge?.id),
      ]);
    };

    const _edgeSelected = () => {
      const data = task.edgeData!;

      const x = Math.min(data.sourceX, data.targetX);
      const y = Math.min(data.sourceY, data.targetY);

      setElements([
        ...nodes.concat({
          id: _edgeSelectedNodeID(data.source, data.target),
          type: 'edgeSelectedNode',
          position: {
            x,
            y,
          },
          data,
          draggable: false,
          selectable: false,
        }),
        ...edges,
      ]);
    };

    const _edgeUnselected = () => {
      const data = task.edgeData!;
      const nodeID = _edgeSelectedNodeID(data.source, data.target);

      setElements([...nodes.filter((node) => node.id !== nodeID), ...edges]);
    };

    const _nodeDeleted = () => {
      const nodeID = task.todo!.id;

      setElements([...nodes.filter((node) => node.id !== nodeID), ...edges]);
    };

    if (task.type === NODE_UPDATED) _updated();
    if (task.type === NODE_CREATED) _created();
    if (task.type === NODE_ADD_MENU_OPENED) _addMenuOpened();
    if (task.type === NODE_ADD_MENU_CLOSED) _addMenuClosed();
    if (task.type === NODE_ADD_EDGE) _addEdge();
    if (task.type === NODE_REMOVE_EDGE) _removeEdge();
    if (task.type === NODE_EDGE_SELECTED) _edgeSelected();
    if (task.type === NODE_EDGE_UNSELECTED) _edgeUnselected();
    if (task.type === NODE_DELETED) _nodeDeleted();

    setTasks((tasks) => tasks.slice(1));
    setTaskProcessed(false);
  }, [containerRef, taskProcessed, tasks, nodes, edges, setElements]);

  const _onConnect = (connection: Edge<any> | Connection) => {
    const { source, target } = connection;
    setEdgeConnected(buildEdge(source!, target!));
  };

  return (
    <DrawContext.Provider
      value={{
        openAddCard,
        closeAddCard,
        isTaskOpened,
        selectEdge,
        unselectEdge,
        started,
      }}
    >
      <ReactFlow
        edgeTypes={edgeTypes}
        elements={elements}
        defaultZoom={0.75}
        nodeTypes={nodeTypes}
        onConnect={_onConnect}
      >
        <Background variant={BackgroundVariant.Dots} gap={24}></Background>
      </ReactFlow>
    </DrawContext.Provider>
  );
};

export default Draw;
