import User from 'domain/authentication/types/User';

const loginAnonymousUseCase = (name: string): User => {
  const user: User = {
    email: '',
    name,
    anonymous: true,
  };

  return user;
};

export default loginAnonymousUseCase;
