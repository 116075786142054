import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { EdgeProps, getBezierPath, getMarkerEnd } from 'react-flow-renderer';

import { DrawContext } from '../Draw';
import { EdgeData } from '../NodeTask';

const useStyles = makeStyles((theme) =>
  createStyles({
    edge: {
      stroke: 'rgb(156, 168, 179)',
      strokeWidth: 1,
      '&:hover': {
        stroke: 'rgb(255, 0, 114)',
        strokeWidth: 4,
      },
    },
  })
);

const TodoEdge: FunctionComponent<EdgeProps> = ({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
  selected,
  target,
}) => {
  const [_selected, _setSelected] = useState(selected);

  const { selectEdge, unselectEdge } = useContext(DrawContext);
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const classes = useStyles();

  const edgeData: EdgeData = useMemo(
    () => ({
      source,
      target,
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    }),
    [
      source,
      target,
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    ]
  );

  useEffect(() => {
    if (selected === _selected) return;
    _setSelected(selected);

    if (!selected) unselectEdge(edgeData);
  }, [selected, _selected, edgeData, unselectEdge]);

  useEffect(() => {
    return () => unselectEdge(edgeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _selectEdge = () => selectEdge(edgeData);

  return (
    <>
      <path
        id={id}
        style={{ ...style, opacity: selected ? 0 : 1 }}
        className={`react-flow__edge-path ${classes.edge}`}
        d={edgePath}
        markerEnd={markerEnd}
        onClick={_selectEdge}
      />
    </>
  );
};

export default TodoEdge;
