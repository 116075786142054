import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { ReactFlowProvider } from 'react-flow-renderer';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';
import TodoArchiveUseCase from 'domain/todo/types/TodoArchiveUseCase';
import areTodoContentsEqualUseCase from 'domain/todo/usecases/areTodoContentsEqualUseCase';

import { Graph, Node } from './Graph';

import TodoTreeProvider from './provider/TodoTreeProvider';
import Draw from './Draw';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: '#1A192B',
      border: `solid rgba(26, 25, 43, 0.054) 1.5px`,
      borderRadius: theme.spacing(1),
      boxShadow: ` 0 2.8px 2.2px rgb(26 25 43 / 1%), 
          0 12.5px 10px rgb(26 25 43 / 2%),
          0 22.3px 17.9px rgb(26 25 43 / 2%),
          0 41.8px 33.4px rgb(26 25 43 / 3%),
          0 100px 80px rgb(26 25 43 / 2%)`,
    },
  })
);

const buildGraph = (todos: Array<Todo>): Graph<Todo> => {
  const nodes: { [key: string]: Node<Todo> } = {};

  for (const todo of todos) nodes[todo.id] = new Node(todo);

  for (const todo of todos)
    if (todo.parentID) {
      const child = nodes[todo.id];
      const parent = nodes[todo.parentID];

      parent.children.push(child);
      child.parent = parent;
    }

  return new Graph(Object.values(nodes));
};

const buildTodosObj = (todos: Array<Todo>) => {
  const todosObj: { [key: string]: Todo } = {};

  for (const todo of todos) todosObj[todo.id] = todo;

  return todosObj;
};

const _updated = (prev: Todo, cur: Todo): boolean => {
  if (!areTodoContentsEqualUseCase(prev.content, cur.content)) return true;

  const prevCompleted = !!prev.completed;
  const curCompleted = !!cur.completed;

  if (prevCompleted !== curCompleted) return true;

  if (prev.childrenCount !== cur.childrenCount) return true;
  if (prev.completedChildren !== cur.completedChildren) return true;

  if (prev.parentID !== cur.parentID) return true;

  return false;
};

type Props = {
  todos: Array<Todo>;

  addEdge: (todoID: string, parentID: string) => Promise<TodoParentChild>;
  deleteEdge: (todoID: string) => Promise<TodoParentChild>;
  createTodoChild: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
  completeTodo: (todo: Todo) => Promise<TodoParentChild>;

  canBeArchived: (todo: Todo) => boolean;
  archive: TodoArchiveUseCase;

  openTodo: (todoID: string) => void;
};
const TodoTree: FunctionComponent<Props> = ({
  todos,

  addEdge,
  deleteEdge,
  createTodoChild,
  completeTodo,

  canBeArchived,
  archive,

  openTodo,
}) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [graph] = useState(buildGraph(todos));
  const [todosObj, setTodosObj] = useState(buildTodosObj(todos));

  const [addedTodo, setAddedTodo] = useState<Todo | null>(null);
  const [updatedTodo, setUpdatedTodo] = useState<Todo | null>(null);
  const [deletedTodo, setDeletedTodo] = useState<Todo | null>(null);

  const resetAddedTodo = useCallback(() => setAddedTodo(null), []);
  const resetUpdatedTodo = useCallback(() => setUpdatedTodo(null), []);
  const resetDeletedTodo = useCallback(() => setDeletedTodo(null), []);

  useEffect(() => {
    const addedTodo = todos.find((todo) => !todosObj[todo.id]);

    if (addedTodo) {
      setTodosObj({
        ...todosObj,
        [addedTodo.id]: addedTodo,
      });

      setAddedTodo(addedTodo);
    }
  }, [todos, todosObj]);

  useEffect(() => {
    const updatedTodo = todos.find(
      (todo) => todosObj[todo.id] && _updated(todosObj[todo.id], todo)
    );

    if (updatedTodo) {
      setTodosObj({
        ...todosObj,
        [updatedTodo.id]: updatedTodo,
      });

      setUpdatedTodo(updatedTodo);
    }
  }, [todos, todosObj]);

  useEffect(() => {
    const oldTodos = Object.values(todosObj);
    const deletedTodo = oldTodos.find(
      (oldTodo) => !todos.find((todo) => todo.id === oldTodo.id)
    );

    if (deletedTodo) {
      setTodosObj((oldTodosObj) => {
        const newTodosObj = { ...oldTodosObj };
        delete newTodosObj[deletedTodo.id];

        return newTodosObj;
      });

      setDeletedTodo(deletedTodo);
    }
  }, [todos, todosObj]);

  return (
    <TodoTreeProvider
      addEdge={addEdge}
      deleteEdge={deleteEdge}
      createTodoChild={createTodoChild}
      completeTodo={completeTodo}
      openTodo={openTodo}
      archive={archive}
      canBeArchived={canBeArchived}
    >
      <ReactFlowProvider>
        <div className={classes.root} ref={containerRef}>
          <Draw
            graph={graph}
            addedTodo={addedTodo}
            resetAddedTodo={resetAddedTodo}
            updatedTodo={updatedTodo}
            resetUpdatedTodo={resetUpdatedTodo}
            deletedTodo={deletedTodo}
            resetDeletedTodo={resetDeletedTodo}
            containerRef={containerRef}
          ></Draw>
        </div>
      </ReactFlowProvider>
    </TodoTreeProvider>
  );
};

export default TodoTree;
