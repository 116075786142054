import { useEffect, useState, useRef } from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';

import useTodoTreeContext from '../../provider/useTodoTreeContext';

const useNodeAddCard = (todo: Todo) => {
  const { createTodoChild } = useTodoTreeContext();

  const [status, setStatus] = useState<LoadStatus | null>(null);

  const [title, setTitle] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (status !== SUBMIT) return;
    setStatus(LOADING);

    const create = async () => {
      try {
        await createTodoChild(todo)({ title });

        setStatus(DONE);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    create();
  }, [status, title, createTodoChild, todo]);

  const onSubmit = () => {
    if (status === SUBMIT || status === LOADING) return;

    if (!title.trim().length) inputRef.current?.focus();
    else setStatus(SUBMIT);
  };

  return {
    onSubmit,
    title,
    setTitle,
    inputRef,
    loading: status === LOADING,
  };
};

export default useNodeAddCard;
