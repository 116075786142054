const Routes = {
  HOME: '/',
  AUTH: {
    ROOT: '/auth',
    LOGIN: {
      ROOT: '/auth/login',
      ANONYMOUS: '/auth/login/anonymous',
    },
    REGISTER: '/auth/register',
  },
  APP: {
    ROOT: '/app',
    TODO: {
      ROOT: '/app/todo/',
      MENU: {
        ROOT: '/app/todo/menu/',
        FORM: '/app/todo/menu/form/:id?',
        form: (id = '') => `/app/todo/menu/form/${id}`,
      },
    },
  },
  LOGOUT: '/logout',
};

export default Routes;
