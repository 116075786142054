import { CircularProgress, Grid } from '@material-ui/core';

const LoadingData = () => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <CircularProgress></CircularProgress>
      </Grid>
    </Grid>
  );
};

export default LoadingData;
