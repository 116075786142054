import User from 'domain/authentication/types/User';

const KEY = 'user';

const UserStorageService = {
  save: (user: User) => localStorage.setItem(KEY, JSON.stringify(user)),
  read: (): User | null => {
    const user = localStorage.getItem(KEY);
    if (!user) return null;

    return JSON.parse(user);
  },
  clear: () => localStorage.removeItem(KEY),
};

export default UserStorageService;
