import {
  useState,
  useEffect,
  useCallback,
  createContext,
  FunctionComponent,
  useMemo,
} from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';
import TodoArchiveUseCase from 'domain/todo/types/TodoArchiveUseCase';

import useUseCases from 'pages/providers/usecases/useUseCases';

type TodoListContextType = {
  retry: () => void;

  todos: Array<Todo>;
  status?: LoadStatus;

  roots: Array<Todo>;

  addEdge?: (todoID: string, parentID: string) => Promise<TodoParentChild>;
  archive?: {
    call: (todoID: string) => Promise<void>;
    onDone: (todoID: string) => void;
  };
  completeTodo?: (todo: Todo) => Promise<TodoParentChild>;
  createTodo?: (content: TodoContent) => Promise<Todo>;
  createTodoChild?: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
  deleteEdge?: (todoID: string) => Promise<TodoParentChild>;
  editTodo?: (id: string) => (content: TodoContent) => Promise<Todo>;
  canBeArchived?: (todo: Todo) => boolean;
};

export const TodoListContext = createContext<TodoListContextType>({
  retry: () => {},
  todos: [],
  roots: [],
});

type Props = {};
const TodoListProvider: FunctionComponent<Props> = ({ children }) => {
  const { todoUseCases } = useUseCases();

  const [todos, setTodos] = useState<Array<Todo>>([]);
  const [status, setStatus] = useState<LoadStatus>();

  const roots = useMemo(() => todos.filter((todo) => !todo.parentID), [todos]);

  const fetchTodos = useCallback(() => {
    if (status === SUBMIT || status === LOADING || status === DONE) return;
    setStatus(SUBMIT);
  }, [status]);

  useEffect(() => {
    if (status !== SUBMIT) return;
    setStatus(LOADING);

    const list = async () => {
      try {
        const fetchedList = await todoUseCases.list();

        setTodos(fetchedList);
        setStatus(DONE);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    list();
  }, [status, todoUseCases]);

  useEffect(() => {
    if (status === ERROR) return;
    fetchTodos();
  }, [status, fetchTodos]);

  const createTodo = useCallback(
    async (content: TodoContent): Promise<Todo> => {
      const todo = await todoUseCases.create(content);

      setTodos((todos) => todos.concat(todo));

      return todo;
    },
    [todoUseCases]
  );

  const createTodoChild = useCallback(
    (parentTodo: Todo) =>
      async (content: TodoContent): Promise<Todo> => {
        const { parent, child } = await todoUseCases.createChild(parentTodo)(
          content
        );

        setTodos((todos) =>
          todos.concat(child).map((todo) => {
            if (todo.id === parent?.id) return parent;
            return todo;
          })
        );

        return child;
      },
    [todoUseCases]
  );

  const editTodo = useCallback(
    (id: string) =>
      async (content: TodoContent): Promise<Todo> => {
        const editedTodo = await todoUseCases.edit(id)(content);

        setTodos((todos) =>
          todos.map((todo) => {
            if (todo.id === editedTodo.id) return editedTodo;
            return todo;
          })
        );

        return editedTodo;
      },
    [todoUseCases]
  );

  const completeTodo = useCallback(
    async (todo: Todo): Promise<TodoParentChild> => {
      const parentChild = await todoUseCases.complete(todo);
      const { child, parent } = parentChild;

      setTodos((todos) =>
        todos.map((todo) => {
          if (todo.id === child.id) return child;
          if (todo.id === parent?.id) return parent;
          return todo;
        })
      );

      return parentChild;
    },
    [todoUseCases]
  );

  const addEdge = useCallback(
    async (todoID: string, parentID: string): Promise<TodoParentChild> => {
      const parentChild = await todoUseCases.addEdge(todoID, parentID);
      const { child, parent } = parentChild;

      setTodos((todos) =>
        todos.map((todo) => {
          if (todo.id === child.id) return child;
          if (todo.id === parent?.id) return parent;
          return todo;
        })
      );

      return parentChild;
    },
    [todoUseCases]
  );

  const deleteEdge = useCallback(
    async (todoID: string): Promise<TodoParentChild> => {
      const todoParentChild = await todoUseCases.deleteEdge(todoID);
      const { child, parent } = todoParentChild;

      setTodos((todos) =>
        todos.map((todo) => {
          if (todo.id === child.id) return child;
          if (todo.id === parent?.id) return parent;
          return todo;
        })
      );

      return todoParentChild;
    },
    [todoUseCases]
  );

  const canBeArchived = useCallback(
    (todo: Todo): boolean => {
      if (todo.parentID) return false;

      const anyChild = todos.find((t) => t.parentID === todo.id);
      if (!!anyChild) return false;

      return true;
    },
    [todos]
  );

  const archive = useMemo((): TodoArchiveUseCase => {
    return {
      call: async (todoID: string) => await todoUseCases.archive(todoID),
      onDone: (todoID: string) =>
        setTodos((todos) => todos.filter((todo) => todo.id !== todoID)),
    };
  }, [todoUseCases]);

  return (
    <TodoListContext.Provider
      value={{
        retry: fetchTodos,
        todos,
        roots,
        status,
        addEdge,
        archive,
        createTodo,
        createTodoChild,
        completeTodo,
        deleteEdge,
        editTodo,
        canBeArchived,
      }}
    >
      {children}
    </TodoListContext.Provider>
  );
};

export default TodoListProvider;
