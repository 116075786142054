import { Grid, makeStyles, createStyles } from '@material-ui/core';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

import AppHeader from './header/AppHeader';
import AppMenu from './menu/AppMenu';

import TodoListProvider from './providers/todo_list/TodoListProvider';

import UnauthenticatedWarning from './UnauthenticatedWarning';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '100%',
      backgroundColor: 'transparent',
    },
    app: {
      flexGrow: 1,
      marginTop: theme.spacing(5),
    },
    menu: {
      flexGrow: 1,
      height: '100%',
    },
  })
);

const AppPage = () => {
  const { isAuthenticated } = useAuthentication();
  const classes = useStyles();

  if (!isAuthenticated)
    return <UnauthenticatedWarning></UnauthenticatedWarning>;

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item container xs={12} sm={10}>
        <TodoListProvider>
          <Grid container direction="column">
            <Grid item container>
              <AppHeader></AppHeader>
            </Grid>

            <Grid item container className={classes.app}>
              <Grid item className={classes.menu}>
                <AppMenu></AppMenu>
              </Grid>
            </Grid>
          </Grid>
        </TodoListProvider>
      </Grid>
    </Grid>
  );
};

export default AppPage;
