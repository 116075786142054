import { createContext, FunctionComponent } from 'react';

import TodoUseCases, { TodoUseCasesFactory } from 'domain/todo/usecases';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

type UseCasesContextType = {
  todoUseCases?: TodoUseCases;
};
export const UseCasesContext = createContext<UseCasesContextType>({});

type Props = {};
const UseCasesProvider: FunctionComponent<Props> = ({ children }) => {
  const { authenticationType } = useAuthentication();

  const todoUseCases = TodoUseCasesFactory(authenticationType);

  return (
    <UseCasesContext.Provider value={{ todoUseCases }}>
      {children}
    </UseCasesContext.Provider>
  );
};

export default UseCasesProvider;
