import { FunctionComponent, MouseEventHandler } from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      textTransform: 'none',
      textDecoration: 'none',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      justifyContent: 'flex-start',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);

type Props = {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: JSX.Element;
  disabled?: boolean;
};
const ItemButton: FunctionComponent<Props> = ({
  label,
  onClick,
  icon,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.root}
      startIcon={icon}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ItemButton;
