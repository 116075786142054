import { Fragment, FunctionComponent, useState } from 'react';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';

import FlatButton from './FlatButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    buttons: {
      marginTop: theme.spacing(1),
    },
  })
);

type Props = {
  renderButton: (onClick: () => void) => JSX.Element;
  onConfirmClick: () => void;
};
const SureButton: FunctionComponent<Props> = ({
  renderButton,
  onConfirmClick,
}) => {
  const [sure, setSure] = useState(false);
  const classes = useStyles();

  const openSure = () => setSure(true);
  const closeSure = () => setSure(false);

  const _onConfirmClick = () => {
    onConfirmClick();
    closeSure();
  };

  return (
    <Fragment>
      {!sure && renderButton(openSure)}
      {sure && (
        <Grid container direction="column" className={classes.root}>
          <Grid item container justify="center">
            <Typography variant="body2">Are you sure ?</Typography>
          </Grid>

          <Grid item container className={classes.buttons} justify="center">
            <Grid item>
              <FlatButton onClick={closeSure}>NO</FlatButton>
            </Grid>

            <Grid item>
              <FlatButton onClick={_onConfirmClick}>
                <b>YES</b>
              </FlatButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default SureButton;
