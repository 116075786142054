import { FunctionComponent } from 'react';
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Fade,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import PaperTextField from 'components/textfield/PaperTextField';
import SubmitButton from 'components/buttons/SubmitButton';

import useTodoInput from './useTodoInput';

const useStyles = makeStyles((theme) =>
  createStyles({
    closeIcon: {
      marginLeft: theme.spacing(1),
    },
    buttons: {
      marginTop: theme.spacing(1),
    },
    closeButton: {
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);

type Props = {
  createTodo: (todoContent: TodoContent) => Promise<Todo>;
  onClose: () => void;
  onTodoCreated: (todo: Todo) => void;
};
const TodoInput: FunctionComponent<Props> = ({
  createTodo,
  onClose,
  onTodoCreated,
}) => {
  const classes = useStyles();
  const {
    title,
    setTitle,
    onSubmit,
    loading,
    titleInputRef,
    failed,
    errorMessage,
  } = useTodoInput({
    createTodo,
    onTodoCreated,
  });

  return (
    <Grid
      container
      tabIndex={-1}
      direction="column"
      onBlur={(e) => {
        const relatedTarget = e.relatedTarget as Node;

        if (
          !loading &&
          relatedTarget != null &&
          !e.currentTarget.contains(relatedTarget)
        )
          onClose();
      }}
    >
      <Grid item container>
        <PaperTextField
          value={title}
          onChange={setTitle}
          autoFocus={true}
          placeholder="Write a title for this ToDo"
          inputRef={titleInputRef}
        ></PaperTextField>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        className={classes.buttons}
        justify="flex-end"
      >
        <Grid item>
          <SubmitButton onClick={onSubmit} isLoading={loading}>
            Add ToDo
          </SubmitButton>
        </Grid>

        <Grid item>
          <IconButton
            size="small"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <Close fontSize="small" className={classes.closeButton}></Close>
          </IconButton>
        </Grid>
      </Grid>

      <Fade in={failed} unmountOnExit>
        <Grid item container justify="flex-end" style={{ marginTop: 24 }}>
          <Grid item>
            <Typography variant="body1" className={classes.error}>
              <i>{errorMessage}</i>
            </Typography>
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default TodoInput;
