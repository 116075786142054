import ApiError, { ApiErrors } from 'domain/errors/ApiError';

import api from 'services/api';

const clearEmailService = async () => {
  try {
    await api.auth.logout();
  } catch (error) {
    if (error instanceof ApiError) {
      const { message } = error;

      if (
        message === ApiErrors.sessionExpired ||
        message === ApiErrors.unauthenticated
      )
        return;
    }

    throw error;
  }
};

export default clearEmailService;
