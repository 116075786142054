import { FC } from 'react';
import {
  Fade,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import RegisterForm, {
  RegisterFormErrors,
} from 'domain/authentication/types/RegisterForm';

import MainTextField from 'components/textfield/MainTextField';
import SubmitButton from 'components/buttons/SubmitButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
      textAlign: 'end',
    },
  })
);

type Props = {
  form: RegisterForm;
  setForm: (form: RegisterForm) => void;
  formErrors: RegisterFormErrors | null;
  onSubmit: () => void;
  error: string;
  loading: boolean;
};
const FormStep: FC<Props> = ({
  form,
  setForm,
  formErrors,
  onSubmit,
  error,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container>
        <MainTextField
          value={form.email}
          onChange={(email) => setForm({ ...form, email })}
          label="Email"
          error={formErrors?.email}
        ></MainTextField>
      </Grid>

      <Grid item container style={{ marginTop: 16 }}>
        <MainTextField
          value={form.name}
          onChange={(name) => setForm({ ...form, name })}
          label="Name"
          error={formErrors?.name}
        ></MainTextField>
      </Grid>

      <Fade in={!!error} unmountOnExit>
        <Grid item container justify="flex-end" style={{ marginTop: 24 }}>
          <Typography variant="body2" className={classes.error}>
            <b>
              <i>{error}</i>
            </b>
          </Typography>
        </Grid>
      </Fade>

      <Grid item container style={{ marginTop: 40 }}>
        <SubmitButton
          fullWidth
          onClick={onSubmit}
          showIcon={false}
          isLoading={loading}
        >
          <b>CREATE ACCOUNT</b>
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

export default FormStep;
