import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoContentValidationError from 'domain/todo/types/TodoContentValidationError';

import validateTodoUseCase, {
  hasErrors,
} from 'domain/todo/usecases/validateTodoUseCase';

import TodoServices from 'services/todo/TodoServices';

const createTodoUseCase = (todoServices: TodoServices) => async (
  content: TodoContent
): Promise<Todo> => {
  const errors = validateTodoUseCase(content);

  if (hasErrors(errors)) throw new TodoContentValidationError(errors);

  const todo = await todoServices.create(content);

  return todo;
};

export default createTodoUseCase;
