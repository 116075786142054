import { FC } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

import CodeInput from 'components/input/code/CodeInput';

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      color: theme.palette.primary.dark,
    },
  })
);

type Props = {
  email: string;
  digits: Array<string>;
  setDigits: (digits: Array<string>) => void;
  error: string;
  loading: boolean;
  submit: () => void;
};
const CodeStep: FC<Props> = ({
  email,
  digits,
  setDigits,
  error,
  loading,
  submit,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            Write confirmation code sent to
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            <b className={classes.bold}>{email}</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item container style={{ marginTop: 24 }}>
        <CodeInput
          digits={digits}
          setDigits={setDigits}
          errorMessage={error}
          submit={submit}
          loading={loading}
        ></CodeInput>
      </Grid>
    </Grid>
  );
};

export default CodeStep;
