import { Grid, makeStyles, createStyles } from '@material-ui/core';

import HomeHeader from 'components/header/HomeHeader';

import LogoutPageBody from './body/LogoutPageBody';

import useLogoutPage from './useLogoutPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
    body: {
      paddingTop: '10vh',
    },
  })
);

const LogoutPage = () => {
  const { loading, done, error, retry } = useLogoutPage();
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item container>
        <HomeHeader></HomeHeader>
      </Grid>

      <Grid item container xs={12} sm={8} md={6} className={classes.body}>
        <LogoutPageBody
          loading={loading}
          done={done}
          error={error}
          retry={retry}
        ></LogoutPageBody>
      </Grid>
    </Grid>
  );
};

export default LogoutPage;
