import { FunctionComponent, Fragment } from 'react';

type Props = {
  header: JSX.Element;
  body: JSX.Element;
};
const AppMenuSection: FunctionComponent<Props> = ({ header, body }) => {
  return <Fragment>{body}</Fragment>;
};

export default AppMenuSection;
