import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import FlatButton from 'components/buttons/FlatButton';
import RaisedButton from 'components/buttons/RaisedButton';

const AuthenticationButtons = () => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Link to={Routes.AUTH.LOGIN.ROOT} style={{ textDecoration: 'none' }}>
          <FlatButton>
            <b>LOGIN</b>
          </FlatButton>
        </Link>
      </Grid>
      <Grid item>
        <Link to={Routes.AUTH.REGISTER} style={{ textDecoration: 'none' }}>
          <RaisedButton>
            <b>REGISTER</b>
          </RaisedButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default AuthenticationButtons;
