import { FunctionComponent } from 'react';
import {
  Dialog,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Routes from 'config/Routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.56)',
    },
    container: {
      backgroundColor: 'rgba(0, 0, 0, 0.56)',
    },
    paper: {
      backgroundColor: theme.palette.grey[200],
    },
    content: {
      display: 'flex',
    },
    dummy: {
      height: '80vh',
    },
    dialog: {
      width: '100%',
      position: 'relative',
    },
    body: {
      height: '100%',
    },
    exitButton: {
      position: 'absolute',
      right: 0,
      margin: theme.spacing(0.5),
      zIndex: 1,
    },
  })
);

type Props = {
  renderBody: () => JSX.Element;
};
const TodoDialog: FunctionComponent<Props> = ({ renderBody }) => {
  const classes = useStyles();
  const history = useHistory();

  const _onClose = () => history.push(Routes.APP.TODO.MENU.ROOT);

  return (
    <Dialog
      open={true}
      onClose={_onClose}
      fullWidth
      maxWidth={'sm'}
      transitionDuration={{
        exit: 0,
        enter: 800,
        appear: 800,
      }}
      classes={{
        root: classes.root,
        paper: classes.paper,
        container: classes.container,
      }}
      scroll="body"
    >
      <div className={classes.content}>
        <div className={classes.dummy}></div>

        <div className={classes.dialog}>
          <div className={classes.exitButton}>
            <IconButton onClick={_onClose}>
              <Close fontSize="small"></Close>
            </IconButton>
          </div>

          <div className={classes.body}>{renderBody()}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default TodoDialog;
