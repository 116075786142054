import { FunctionComponent } from 'react';
import {
  Fab,
  Fade,
  Grid,
  Grow,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import MainTextField from 'components/textfield/MainTextField';

import ParentLink from './ParentLink';
import Title from './Title';
import TasksForm from './tasks/TasksForm';
import CompleteTodo from './complete/CompleteTodo';

import TodoFormDialogProvider from './TodoFormDialogProvider';
import useTodoFormDialog from './useTodoFormDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
      padding: theme.spacing(3),
    },
    parent: {
      marginTop: theme.spacing(0.5),
    },
    description: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    tasks: {
      marginBottom: theme.spacing(5),
    },
    complete: {
      flexGrow: 1,
    },
    fab: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      margin: theme.spacing(1.5),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

type Props = {
  parentChild: TodoParentChild;

  router: (todoID: string) => void;
  completeTodo: (todo: Todo) => Promise<TodoParentChild>;
  createTodoChild: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
  editTodo: (todoID: string) => (content: TodoContent) => Promise<Todo>;
};
const TodoFormDialog: FunctionComponent<Props> = ({
  parentChild,

  router,
  completeTodo,
  createTodoChild,
  editTodo,
}) => {
  const classes = useStyles();
  const { child, parent } = parentChild;
  const {
    content,
    hasChanged,
    setContent,
    errors,
    onSubmit,
    onTaskCreated,
    onTodoCompleted,
    todoRoot,
  } = useTodoFormDialog(child, editTodo);
  const { completed } = todoRoot;
  const tasks = todoRoot.children || [];

  return (
    <TodoFormDialogProvider router={router}>
      <Fade in={true} unmountOnExit>
        <Grid container direction="column" className={classes.root}>
          <Grid item container>
            <Title
              completed={completed}
              title={content.title}
              onChange={(title) => setContent({ ...content, title })}
              error={errors.title}
            ></Title>
          </Grid>

          {parent && (
            <Grid item container className={classes.parent}>
              <ParentLink parent={parent}></ParentLink>
            </Grid>
          )}

          <Grid item container className={classes.description}>
            <MainTextField
              label="Description"
              fullWidth={true}
              value={content.description || ''}
              onChange={(description) =>
                setContent({ ...content, description })
              }
              error={errors.description}
              multiline={true}
            ></MainTextField>
          </Grid>

          <Grid item container className={classes.tasks}>
            <TasksForm
              parent={todoRoot}
              tasks={tasks}
              onChildCreated={onTaskCreated}
              completed={completed}
              createTodoChild={createTodoChild}
            ></TasksForm>
          </Grid>

          <Grid
            item
            container
            className={classes.complete}
            alignItems="flex-end"
          >
            <CompleteTodo
              todo={todoRoot}
              tasks={tasks}
              onTodoCompleted={onTodoCompleted}
              completeTodo={completeTodo}
            ></CompleteTodo>
          </Grid>

          <Grow in={hasChanged} unmountOnExit>
            <Fab className={classes.fab} onClick={onSubmit}>
              <Save></Save>
            </Fab>
          </Grow>
        </Grid>
      </Fade>
    </TodoFormDialogProvider>
  );
};

export default TodoFormDialog;
