import { useEffect, useState } from 'react';

import LoadStatus, { ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import useTodoTreeContext from '../provider/useTodoTreeContext';

const useRemoveButton = (todoID: string) => {
  const { deleteEdge } = useTodoTreeContext();
  const [status, setStatus] = useState<LoadStatus>();

  useEffect(() => {
    if (status !== SUBMIT) return;
    setStatus(LOADING);

    const request = async () => {
      try {
        await deleteEdge(todoID);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    request();
  }, [status, deleteEdge, todoID]);

  const submit = () => {
    if (status === SUBMIT || status === LOADING) return;
    setStatus(SUBMIT);
  };

  return {
    loading: status === LOADING,
    submit,
  };
};

export default useRemoveButton;
