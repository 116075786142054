import AnonymousStorage, { ITodo, mapITodoToTodo } from './AnonymousStorage';

import TodoParentChild from 'domain/todo/types/TodoParentChild';
import { NotFoundError, ParentNotFoundError } from 'domain/errors';

const completeTodoAnonymousService =
  (db: AnonymousStorage) =>
  async (todoID: string): Promise<TodoParentChild> => {
    const { iTodo, parentITodo } = await db.ref.transaction(
      'readwrite',
      db.todos,
      async () => {
        const _todoID = parseInt(todoID);

        const iTodo = await db.todos.get(_todoID);

        if (!iTodo) throw new NotFoundError();

        const _updateParent = async () => {
          if (!iTodo.parentID) return;

          const parentITodo = await db.todos.get(iTodo.parentID);

          if (!parentITodo) throw new ParentNotFoundError();

          const updatedParentITodo: ITodo = {
            ...parentITodo,
            completedChildren:
              (parentITodo?.completedChildren || 0) +
              (iTodo.completed ? -1 : 1),
          };

          await db.todos.update(iTodo.parentID, updatedParentITodo);

          return updatedParentITodo;
        };

        const updatedITodo: ITodo = {
          ...iTodo,
          completed: !iTodo.completed,
          completedTimestamp: Date.now(),
        };
        if (iTodo.completed) delete updatedITodo.completedTimestamp;
        await db.todos.update(_todoID, updatedITodo);

        const updatedParentITodo = await _updateParent();

        return {
          iTodo: updatedITodo,
          parentITodo: updatedParentITodo,
        };
      }
    );

    const todo = mapITodoToTodo(iTodo);

    if (parentITodo)
      return {
        child: todo,
        parent: mapITodoToTodo(parentITodo),
      };

    return {
      child: todo,
    };
  };

export default completeTodoAnonymousService;
