import axios from 'axios';

import { DEV_SERVER_URL, SERVER_URL } from 'config/server';

import ApiError from 'domain/errors/ApiError';
import Session from 'domain/authentication/types/Session';

import SessionStorageService from 'services/authentication/storage/session';

const SESSION_ID = 'session-id';
const REFRESH_SESSION_ID = 'refresh-session-id';

const initializeInterceptors = () => {
  axios.defaults.baseURL =
    process.env.NODE_ENV === 'production' ? SERVER_URL : DEV_SERVER_URL;

  axios.interceptors.request.use((config) => {
    const session = SessionStorageService.read();

    if (session) {
      const { sessionID, refreshSessionID } = session;

      config.headers[SESSION_ID] = sessionID;
      config.headers[REFRESH_SESSION_ID] = refreshSessionID;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.headers[SESSION_ID]) {
        const sessionID = response.headers[SESSION_ID];
        const refreshSessionID = response.headers[REFRESH_SESSION_ID];

        const session: Session = {
          sessionID,
          refreshSessionID,
        };

        SessionStorageService.save(session);
      }

      return response;
    },
    (error) => {
      if (error.response) {
        const message = error.response.data as string;
        const status = error.response.status as number;

        throw new ApiError(status, message);
      }

      throw error;
    }
  );
};

export default initializeInterceptors;
