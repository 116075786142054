import { FunctionComponent } from 'react';

import useTodoList from 'pages/app/providers/todo_list/useTodoList';

import TodoInput from '../TodoInput';

type Props = {
  onClose: () => void;
};
const TodoInputRoot: FunctionComponent<Props> = ({ onClose }) => {
  const { createTodo } = useTodoList();

  return (
    <TodoInput
      createTodo={createTodo}
      onClose={onClose}
      onTodoCreated={onClose}
    ></TodoInput>
  );
};

export default TodoInputRoot;
