import { FunctionComponent } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

type Props = {};
const HeaderBreadcrumbs: FunctionComponent<Props> = ({ children }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="App header breadcrumps"
    >
      {children}
    </Breadcrumbs>
  );
};

export default HeaderBreadcrumbs;
