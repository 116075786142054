import { FunctionComponent } from 'react';
import { ThemeProvider } from '@material-ui/styles';

import MainTheme from './MainTheme';

type Props = {};
const MainThemeProvider: FunctionComponent<Props> = ({ children }) => {
  return <ThemeProvider theme={MainTheme}>{children}</ThemeProvider>;
};

export default MainThemeProvider;
