import TodoServices from '../TodoServices';

import AnonymousStorage from './AnonymousStorage';

import addEdgeAnonymousService from './addEdgeAnonymousService';
import archiveTodoAnonymousService from './archiveTodoAnonymousService';
import createTodoAnonymousService from './createTodoAnonymousService';
import createTodoChildAnonymousService from './createTodoChildAnonymousService';
import listTodosAnonymousService from './listTodosAnonymousService';
import readTodoAnonymousService from './readTodoAnonymousService';
import editTodoAnonymousService from './editTodoAnonymousService';
import completeTodoAnonymousService from './completeTodoAnonymousService';
import clearTodosAnonymousService from './clearTodosAnonymousService';
import deleteEdgeAnonymousService from './deleteEdgeAnonymousService';

const AnonymousTodoServices = (): TodoServices => {
  const db = new AnonymousStorage();

  return {
    addEdge: addEdgeAnonymousService(db),
    archive: archiveTodoAnonymousService(db),
    clear: clearTodosAnonymousService(db),
    complete: completeTodoAnonymousService(db),
    create: createTodoAnonymousService(db),
    createChild: createTodoChildAnonymousService(db),
    deleteEdge: deleteEdgeAnonymousService(db),
    edit: editTodoAnonymousService(db),
    list: listTodosAnonymousService(db),
    read: readTodoAnonymousService(db),
  };
};

const anonymousTodoServices = AnonymousTodoServices();

export default anonymousTodoServices;
