import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import FlatButton from 'components/buttons/FlatButton';

type Props = {
  retry: () => void;
};
const LoadingFailed: FC<Props> = ({ retry }) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h5">App initialization failed</Typography>
      </Grid>

      <Grid item>
        <FlatButton textTransform="none" onClick={retry}>
          <b>Try Again</b>
        </FlatButton>
      </Grid>
    </Grid>
  );
};

export default LoadingFailed;
