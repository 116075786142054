import { FunctionComponent } from 'react';
import {
  Paper,
  Grid,
  makeStyles,
  createStyles,
  Icon,
  Typography,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.80)',
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[50],
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:hover': {
        backgroundColor: '#FFFFFF40',
      },
    },
    title: {
      width: '100%',
    },
    text: {
      lineHeight: 1.5,
    },
    completedIcon: {
      fontSize: theme.spacing(2),
      color: theme.palette.primary.dark,
    },
  })
);

type Props = {
  todo: Todo;

  onClick?: () => void;
};
const TodoCard: FunctionComponent<Props> = ({ todo, onClick }) => {
  const classes = useStyles();

  const { content, completed } = todo;
  const { title } = content;

  return (
    <Paper className={classes.root} tabIndex={0} onClick={onClick}>
      <Grid container alignItems="center">
        <Grid item container className={classes.title} xs={10}>
          <Typography variant="body1" className={classes.text}>
            {title}
          </Typography>
        </Grid>

        <Grid item container justify="flex-end" xs={2}>
          {completed && (
            <Icon className={classes.completedIcon}>
              <CheckCircle fontSize="inherit"></CheckCircle>
            </Icon>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TodoCard;
