import { CannotBeArchived, NotFoundError } from 'domain/errors';

import AnonymousStorage, { ITodo } from './AnonymousStorage';

const archiveTodoAnonymousService =
  (db: AnonymousStorage) => async (todoID: string) => {
    await db.ref.transaction('readwrite', db.todos, async () => {
      const _todoID = parseInt(todoID);
      const iTodo = await db.todos.get(_todoID);
      if (!iTodo) throw new NotFoundError();

      if (iTodo.parentID) throw new CannotBeArchived('ToDo has a parent');

      const children = await db.todos
        .filter((iTodo) => iTodo.parentID === _todoID)
        .toArray();
      if (children.length > 0) throw new CannotBeArchived('ToDo has a child');

      const updatedITodo: ITodo = {
        ...iTodo,
        archived: true,
      };
      await db.todos.update(_todoID, updatedITodo);
    });
  };

export default archiveTodoAnonymousService;
