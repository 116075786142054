import { useParams } from 'react-router-dom';

import useTodoFormRead from 'components/todo/form/hooks/useTodoFormRead';

type ParamsType = {
  id: string;
};

const useEditTodoDialog = () => {
  const { id } = useParams<ParamsType>();
  const { loading, tryAgain, done, failed, parentChild, notFound } =
    useTodoFormRead(id);

  return {
    loading,
    done,
    parentChild: parentChild!,
    failed,
    tryAgain,
    notFound,
  };
};

export default useEditTodoDialog;
