export const UNAUTHENTICATED = 'unauthenticated';
export const ANONYMOUS = 'anonymous_authentication';
export const EMAIL = 'email_authentication';

type AuthenticationType =
  | typeof UNAUTHENTICATED
  | typeof ANONYMOUS
  | typeof EMAIL;

export default AuthenticationType;
