import { useContext } from 'react';

import { AuthenticationContext } from './AuthenticationProvider';

const useAuthentication = () => {
  const {
    isAuthenticated,
    user,
    login,
    loginAnonymous,
    authenticationType,
    logout,
  } = useContext(AuthenticationContext);

  return {
    isAuthenticated,
    user,
    login,
    loginAnonymous,
    logout,
    authenticationType,
  };
};

export default useAuthentication;
