import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import api from 'services/api';

const createTodoChildEmailService =
  (parentID: string) =>
  async (todoContent: TodoContent): Promise<TodoParentChild> => {
    const todoParentChild = await api.todo.createChild(parentID)(todoContent);
    return todoParentChild;
  };

export default createTodoChildEmailService;
