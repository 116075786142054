import TodoServices from 'services/todo/TodoServices';
import TodoParentChild from '../types/TodoParentChild';

const addEdgeUseCase =
  (todoServices: TodoServices) =>
  async (todoID: string, parentID: string): Promise<TodoParentChild> => {
    const parentChild = await todoServices.addEdge(todoID, parentID);

    return parentChild;
  };

export default addEdgeUseCase;
