import { Fragment } from 'react';

import TodoList from 'components/todo/list/TodoList';

import TodoMenuDialog from './dialog/TodoMenuDialog';

import useTodoMenu from './useTodoMenu';

const TodoMenu = () => {
  const { todos } = useTodoMenu();

  return (
    <Fragment>
      <TodoMenuDialog></TodoMenuDialog>

      <TodoList todos={todos}></TodoList>
    </Fragment>
  );
};

export default TodoMenu;
