import { FunctionComponent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

type StylesProps = {
  add?: boolean;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1.5),
      marginTop: (props: StylesProps) => (props.add ? theme.spacing(0.5) : 0),
    },
  })
);

type Props = {
  text: string;
  icon?: JSX.Element;
  addMarginTopIcon?: boolean;
};
const ItemBase: FunctionComponent<Props> = ({
  text,
  icon,
  addMarginTopIcon = true,
}) => {
  const classes = useStyles({ add: addMarginTopIcon });

  return (
    <div className={classes.root}>
      {icon && <span className={classes.icon}>{icon}</span>}

      {text}
    </div>
  );
};

export default ItemBase;
