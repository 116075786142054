import { Grid, makeStyles, createStyles } from '@material-ui/core';

import { Link } from 'react-router-dom';
import AppTitle from 'components/typography/AppTitle';

import Routes from 'config/Routes';

import ProfileAvatar from './avatar/ProfileAvatar';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `0 ${theme.spacing(3)}px`,
      paddingTop: theme.spacing(2),
    },
  })
);

const AppHeader = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        <Link to={Routes.APP.ROOT} style={{ textDecoration: 'none' }}>
          <AppTitle textAlign="start"></AppTitle>
        </Link>
      </Grid>

      <div style={{ flexGrow: 1 }}></div>

      <Grid item>
        <ProfileAvatar></ProfileAvatar>
      </Grid>
    </Grid>
  );
};

export default AppHeader;
