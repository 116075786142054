import Todo from 'domain/todo/types/Todo';

import api from 'services/api';

const listTodosEmailService = async (): Promise<Array<Todo>> => {
  const todos = await api.todo.list();
  return todos;
};

export default listTodosEmailService;
