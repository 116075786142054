class ApiError {
  status: number;
  message: string;

  constructor(status: number, message: string) {
    this.status = status;
    this.message = message;
  }
}

export const ApiErrors = {
  sessionExpired: 'session_expired',
  unauthenticated: 'unauthenticated',
};

export default ApiError;
