import axios from 'axios';

import RegisterForm from 'domain/authentication/types/RegisterForm';
import User from 'domain/authentication/types/User';

const login = {
  sendCode: async (email: string): Promise<string> => {
    const res = await axios.post('/api/auth/login/send', {
      email,
    });

    const token: string = res.data;
    return token;
  },
  confirmCode:
    (token: string) =>
    async (code: string): Promise<User> => {
      const res = await axios.post('/api/auth/login/confirm', {
        code,
        token,
      });

      const user = res.data as User;
      return user;
    },
};

const register = {
  sendCode: async (form: RegisterForm): Promise<string> => {
    const { email, name } = form;

    const res = await axios.post('/api/auth/register/send', {
      email,
      name,
    });

    const token = res.data as string;

    return token;
  },
  confirmCode:
    (token: string) =>
    async (code: string): Promise<User> => {
      const res = await axios.post('/api/auth/register/confirm', {
        token,
        code,
      });

      const user = res.data as User;
      return user;
    },
};

const logout = async () => {
  await axios.post('/api/auth/logout');
};

const auth = {
  login,
  logout,
  register,
};

export default auth;
