import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const useBreakpoint = (breakpoint: Breakpoint) => {
  const theme = useTheme();
  const isUp = useMediaQuery(theme.breakpoints.up(breakpoint), {
    noSsr: true,
  });

  return isUp;
};

export default useBreakpoint;
