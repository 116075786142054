import ApiError from 'domain/errors/ApiError';
import RegisterForm, {
  RegisterFormErrors,
} from 'domain/authentication/types/RegisterForm';
import User from 'domain/authentication/types/User';

import RegisterServices from 'services/authentication/RegisterServices';

const RegisterUseCases = {
  validate: (registerForm: RegisterForm): RegisterFormErrors | null => {
    const { email, name } = registerForm;
    const errors: RegisterFormErrors = {};

    if (!email || !email.trim()) errors.email = 'Email should not be empty';
    if (!name || !name.trim()) errors.name = 'Name should not be empty';

    if (!Object.keys(errors).length) return null;
    return errors;
  },
  sendCode: async (registerForm: RegisterForm): Promise<string> => {
    try {
      const token = await RegisterServices.sendCode(registerForm);
      return token;
    } catch (error) {
      if (error instanceof ApiError) {
        const { message } = error;

        if (message === 'invalid_email') throw new InvalidEmail();
        if (message === 'email_already_picked') throw new EmailAlreadyPicked();
      }

      throw error;
    }
  },
  confirmCode:
    (token: string) =>
    async (code: string): Promise<User> => {
      try {
        const user = await RegisterServices.confirmCode(token)(code);
        return user;
      } catch (error) {
        if (error instanceof ApiError) {
          const { message } = error;

          if (message === 'wrong_code' || message === 'invalid_code')
            throw new WrongCode();
          if (message === 'invalid_token') throw new ExpiredCode();
        }
        throw error;
      }
    },
};

export class InvalidEmail {}
export class EmailAlreadyPicked {}

export class WrongCode {}
export class ExpiredCode {}

export default RegisterUseCases;
