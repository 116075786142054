import { ParentNotFoundError } from 'domain/errors';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import AnonymousStorage, { ITodo, mapITodoToTodo } from './AnonymousStorage';

const createTodoChildAnonymousService =
  (db: AnonymousStorage) =>
  (parentID: string) =>
  async (todoContent: TodoContent): Promise<TodoParentChild> => {
    const { iTodo, parentITodo } = await db.ref.transaction(
      'readwrite',
      db.todos,
      async () => {
        const _parentID = parseInt(parentID);
        const parentITodo = await db.todos.get(_parentID);

        if (!parentITodo) throw new ParentNotFoundError();

        const id = await db.todos.add({
          content: todoContent,
          parentID: _parentID,
        });
        const iTodo: ITodo = {
          id,
          content: todoContent,
          parentID: _parentID,
        };

        const updatedParentITodo = {
          ...parentITodo,
          childrenCount: (parentITodo.childrenCount || 0) + 1,
        };

        await db.todos.update(_parentID, updatedParentITodo);

        return {
          iTodo,
          parentITodo: updatedParentITodo,
        };
      }
    );

    return {
      child: mapITodoToTodo(iTodo),
      parent: mapITodoToTodo(parentITodo),
    };
  };

export default createTodoChildAnonymousService;
