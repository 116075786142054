import { FunctionComponent, useCallback, useState } from 'react';
import {
  Grid,
  Collapse,
  Fade,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import Todo from 'domain/todo/types/Todo';

import TodoInputRoot from 'components/todo/input/root/TodoInputRoot';

import TodoListHeader from './TodoListHeader';
import TodoListTree from './TodoListTree';

const useStyles = makeStyles((theme) =>
  createStyles({
    collapsed: {
      width: '100%',
    },
  })
);

type Props = {
  todos: Array<Todo>;
};
const TodoList: FunctionComponent<Props> = ({ todos }) => {
  const [showAddField, setShowAddField] = useState(false);
  const classes = useStyles();

  const _onOpen = useCallback(() => setShowAddField(true), []);
  const _onClose = useCallback(() => setShowAddField(false), []);

  return (
    <Grid container direction="column" style={{ height: 'inherit' }}>
      <Grid item container>
        <Grid container item xs={12} sm={6} md={4}>
          <TodoListHeader
            onAddButtonClick={_onOpen}
            showAddButton={!showAddField}
          ></TodoListHeader>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid container item xs={12} sm={6} md={4}>
          <Collapse
            in={showAddField}
            timeout={100}
            unmountOnExit
            className={classes.collapsed}
          >
            <TodoInputRoot onClose={_onClose}></TodoInputRoot>
          </Collapse>
        </Grid>
      </Grid>

      <Grid item container style={{ marginTop: 16 }}></Grid>

      <Fade in={true} unmountOnExit>
        <Grid item container style={{ flexGrow: 1 }}>
          <TodoListTree todos={todos}></TodoListTree>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default TodoList;
