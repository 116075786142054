import { Grid, Typography } from '@material-ui/core';

const EmptyList = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1">You have no ToDo's</Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyList;
