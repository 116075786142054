import { FC } from 'react';
import { Grid } from '@material-ui/core';

import Todo from 'domain/todo/types/Todo';

import TodoCard from 'components/todo/TodoCard';

type Props = {
  roots: Array<Todo>;
};
const HorizontalList: FC<Props> = ({ roots }) => {
  return (
    <Grid container spacing={3}>
      {roots.map((root) => {
        return (
          <Grid item lg={2} md={3} sm={4} xs={12} key={root.id}>
            <TodoCard todo={root}></TodoCard>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default HorizontalList;
