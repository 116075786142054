import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import api from 'services/api';

const editTodoEmailService =
  (id: string) =>
  async (todoContent: TodoContent): Promise<Todo> => {
    const todo = await api.todo.edit(id)(todoContent);
    return todo;
  };

export default editTodoEmailService;
