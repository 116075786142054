import { FunctionComponent, RefObject } from 'react';
import { Paper, TextField, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      backgroundColor: theme.palette.grey[50],
    },
  })
);

type Props = {
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  placeholder: string;
  inputRef?: RefObject<HTMLInputElement>;
};
const PaperTextField: FunctionComponent<Props> = ({
  value,
  onChange,
  autoFocus = false,
  placeholder,
  inputRef,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TextField
        inputRef={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        placeholder={placeholder}
        InputProps={{ disableUnderline: true }}
        autoFocus={autoFocus}
        fullWidth
        multiline
      ></TextField>
    </Paper>
  );
};

export default PaperTextField;
