import { useEffect, useState } from 'react';

import LoadStatus, { DONE, ERROR, LOADING } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import delayed from 'utils/delayed';

const useCompleteTodo = (
  todo: Todo,
  onTodoCompleted: (todo: Todo) => void,
  completeTodo: (todo: Todo) => Promise<TodoParentChild>
) => {
  const [status, setStatus] = useState<LoadStatus | null>();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (!submit) return;

    setSubmit(false);
    setStatus(LOADING);

    const complete = async () => {
      try {
        const { child } = await delayed(completeTodo(todo));

        onTodoCompleted(child);
        setStatus(DONE);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    complete();
  }, [submit, completeTodo, onTodoCompleted, todo]);

  const onSubmit = () => {
    if (submit) return;
    setSubmit(true);
  };

  return {
    isLoading: status === LOADING,
    onSubmit,
  };
};

export default useCompleteTodo;
