import HeaderBreadcrumbs from '../HeaderBreadcrumbs';

import AppItem from '../items/AppItem';
import DashboardItem from '../items/DashboardItem';

const DashboardBreadcrumbs = () => {
  return (
    <HeaderBreadcrumbs>
      <AppItem></AppItem>
      <DashboardItem></DashboardItem>
    </HeaderBreadcrumbs>
  );
};

export default DashboardBreadcrumbs;
