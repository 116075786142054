import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import TodoServices from 'services/todo/TodoServices';
import TodoParentChild from '../types/TodoParentChild';

const createTodoChildUseCase =
  (todoServices: TodoServices) =>
  (parent: Todo) =>
  async (content: TodoContent): Promise<TodoParentChild> => {
    const { title } = content;
    const { completed } = parent;

    if (!title.trim().length) throw new Error('content is invalid');

    if (completed) throw new Error('parent ToDo was completed');

    const parentChild = await todoServices.createChild(parent.id)(content);

    return parentChild;
  };

export default createTodoChildUseCase;
