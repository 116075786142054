import Todo from 'domain/todo/types/Todo';
import AnonymousStorage, { mapITodoToTodo } from './AnonymousStorage';

const listTodosAnonymousService =
  (db: AnonymousStorage) => async (): Promise<Array<Todo>> => {
    const iTodos = await db.todos.toArray();
    const todos = iTodos
      .filter((iTodo) => !iTodo.archived)
      .map((iTodo) => mapITodoToTodo(iTodo));

    return Object.values(todos);
  };

export default listTodosAnonymousService;
