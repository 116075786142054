import { useState, useCallback, useEffect } from 'react';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import useTodoFormEdit from '../hooks/useTodoFormEdit';

const useTodoFormDialog = (
  initialTodo: Todo,
  editTodo: (todoID: string) => (content: TodoContent) => Promise<Todo>
) => {
  const [todoRoot, setTodoRoot] = useState(initialTodo);
  const [createdTask, setCreatedTask] = useState<Todo | null>(null);
  const { content, setContent, errors, hasChanged, onSubmit } = useTodoFormEdit(
    initialTodo,
    editTodo
  );

  useEffect(() => {
    const onChildCreated = () => {
      if (!createdTask) return;

      setCreatedTask(null);
      setTodoRoot({
        ...todoRoot,
        children: todoRoot.children
          ? [createdTask].concat(todoRoot.children)
          : [createdTask],
      });
    };
    onChildCreated();
  }, [createdTask, todoRoot]);

  const onTaskCreated = useCallback((todo: Todo) => setCreatedTask(todo), []);

  const onTodoCompleted = useCallback(
    (todo: Todo) => {
      const children = todoRoot.children;
      setTodoRoot({
        ...todo,
        children,
        completedTimestamp: todo.completed
          ? todo.completedTimestamp
          : todoRoot.completedTimestamp,
      });
    },
    [todoRoot]
  );

  return {
    content,
    hasChanged,
    setContent,
    errors,
    onSubmit,
    onTaskCreated,
    onTodoCompleted,
    todoRoot,
  };
};

export default useTodoFormDialog;
