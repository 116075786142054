import { useContext } from 'react';

import { UseCasesContext } from './UseCasesProvider';

const useUseCases = () => {
  const { todoUseCases } = useContext(UseCasesContext);

  return { todoUseCases: todoUseCases! };
};

export default useUseCases;
