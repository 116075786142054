import { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';

import Todo from 'domain/todo/types/Todo';

import { useTodoFormDialogContext } from './TodoFormDialogProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: 'rgba(0, 0, 0, 0.56)',
    },
    link: {
      cursor: 'pointer',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
  })
);

type Props = {
  parent: Todo;
};
const ParentLink: FunctionComponent<Props> = ({ parent }) => {
  const { router } = useTodoFormDialogContext();

  const classes = useStyles();
  const { id, content } = parent;
  const { title } = content;

  return (
    <Grid container>
      <Grid item>
        <Typography variant="body2" className={classes.text}>
          task for{' '}
          <span
            className={classes.link}
            onClick={() => router(id)}
            tabIndex={0}
          >
            {title}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ParentLink;
