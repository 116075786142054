import { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import ItemButton from 'components/buttons/ItemButton';
import SureButton from 'components/buttons/SureButton';

import useRemoveButton from './useRemoveButton';

type Props = {
  todoID: string;
};
const RemoveButton: FunctionComponent<Props> = ({ todoID }) => {
  const { submit, loading } = useRemoveButton(todoID);

  return (
    <SureButton
      onConfirmClick={submit}
      renderButton={(onClick) => {
        return (
          <ItemButton
            icon={
              loading ? (
                <CircularProgress size={18}></CircularProgress>
              ) : (
                <DeleteOutline></DeleteOutline>
              )
            }
            onClick={loading ? () => {} : onClick}
            label="Delete edge"
          ></ItemButton>
        );
      }}
    ></SureButton>
  );
};

export default RemoveButton;
