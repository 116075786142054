import { useContext } from 'react';

import { TodoTreeContext } from './TodoTreeProvider';

const useTodoTreeContext = () => {
  const {
    addEdge,
    deleteEdge,
    createTodoChild,
    completeTodo,
    openTodo,
    archive,
    canBeArchived,
  } = useContext(TodoTreeContext);

  return {
    addEdge: addEdge!,
    deleteEdge: deleteEdge!,
    createTodoChild: createTodoChild!,
    completeTodo: completeTodo!,

    archive: archive!,
    canBeArchived: canBeArchived!,

    openTodo: openTodo!,
  };
};

export default useTodoTreeContext;
