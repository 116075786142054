import { FunctionComponent } from 'react';
import { Slide, Grid, Icon, makeStyles, createStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import FocusTextField from 'components/textfield/FocusTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    completedIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2.5),
    },
    field: {
      flexGrow: 1,
    },
  })
);

type Props = {
  completed?: boolean;
  title: string;
  onChange: (title: string) => void;
  error?: string;
};
const Title: FunctionComponent<Props> = ({
  completed,
  title,
  onChange,
  error,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Slide in={completed} direction="right" unmountOnExit>
        <Grid item>
          <Icon className={classes.completedIcon}>
            <CheckCircle fontSize="inherit"></CheckCircle>
          </Icon>
        </Grid>
      </Slide>

      <Grid item className={classes.field}>
        <FocusTextField
          value={title}
          onChange={onChange}
          error={error}
        ></FocusTextField>
      </Grid>
    </Grid>
  );
};

export default Title;
