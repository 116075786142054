import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import AnonymousStorage from './AnonymousStorage';

const createTodoAnonymousService = (db: AnonymousStorage) => async (
  todoContent: TodoContent
): Promise<Todo> => {
  const id = await db.todos.add({ content: todoContent });

  return {
    id: id.toString(),
    content: todoContent,
  };
};

export default createTodoAnonymousService;
