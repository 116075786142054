import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import FlatButton from 'components/buttons/FlatButton';
import RaisedButton from 'components/buttons/RaisedButton';
import Hero from 'components/todo/home/Hero';

import useBreakpoint from 'utils/useBreakpoint';

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      marginTop: theme.spacing(5),
    },
    buttons: {
      marginTop: theme.spacing(5),
      display: 'flex',
    },
    bold: {
      color: theme.palette.primary.light,
    },
  })
);

const AppDescription = () => {
  const classes = useStyles();
  const isUpMD = useBreakpoint('md');
  const history = useHistory();

  const redirect = (path: string) => () => history.push(path);

  return (
    <Grid container direction="column">
      {isUpMD && (
        <Grid item container direction="column">
          <Grid item container justify="center">
            <Typography
              style={{
                textAlign: 'center',
                marginBottom: 16,
                fontFamily: 'Sriracha, cursive',
              }}
              variant="h3"
            >
              <b>
                Start testing <span className={classes.bold}>below</span>
              </b>
            </Typography>
          </Grid>

          <Grid
            item
            container
            justify="center"
            style={{ width: '100%', height: 304 }}
          >
            <Grid item container md={7}>
              <Hero></Hero>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item container justify="center" style={{ marginTop: 64 }}>
        <Grid item container xs={10} sm={6} md={4} direction="column">
          <Grid item container alignItems="center" direction="column">
            <Grid item>
              <Typography variant="h4" style={{ textAlign: 'center' }}>
                <b>Organize your ToDo's</b>
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h4" style={{ textAlign: 'center' }}>
                <b>
                  in a <span className={classes.bold}>tree</span> UI
                </b>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container className={classes.description} justify="center">
            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
              }}
            >
              Manage your tasks in an interactive interface. Break them in
              smaller subtasks and see how they depend on each other
            </Typography>
          </Grid>

          <Grid item container className={classes.buttons} direction="column">
            <Grid item container justify="center">
              <RaisedButton
                textTransform="none"
                onClick={redirect(Routes.AUTH.REGISTER)}
              >
                <b>Sign up for free!</b>
              </RaisedButton>
            </Grid>

            <Grid item container justify="center" style={{ marginTop: 8 }}>
              <FlatButton
                textTransform="none"
                onClick={redirect(Routes.AUTH.LOGIN.ANONYMOUS)}
              >
                <b>Test without creating an account</b>
              </FlatButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDescription;
