import { NotFoundError, ParentNotFoundError } from 'domain/errors';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import AnonymousStorage, { ITodo, mapITodoToTodo } from './AnonymousStorage';

const readTodoAnonymousService =
  (db: AnonymousStorage) =>
  async (id: string): Promise<TodoParentChild> => {
    const { parentITodo, savedITodo, childrenITodo } = await db.ref.transaction(
      'readonly',
      db.todos,
      async () => {
        const todoID = parseInt(id);
        const savedITodo = await db.todos.get(todoID);

        if (!savedITodo) throw new NotFoundError();

        const _getParentITodo = async (
          parentID: number | undefined
        ): Promise<ITodo | undefined> => {
          if (!parentID) return undefined;

          const parentITodo = await db.todos.get(parentID);

          if (!parentITodo) throw new ParentNotFoundError();

          return parentITodo;
        };
        const parentITodo = await _getParentITodo(savedITodo.parentID);

        const _childrenITodo = async (id: number): Promise<Array<ITodo>> => {
          return await db.todos
            .filter((iTodo) => iTodo.parentID === id)
            .toArray();
        };
        const childrenITodo = await _childrenITodo(todoID);

        return {
          savedITodo,
          parentITodo,
          childrenITodo,
        };
      }
    );

    const todo = mapITodoToTodo(savedITodo);
    todo.children = childrenITodo.map((iTodo) => mapITodoToTodo(iTodo));

    if (parentITodo)
      return {
        parent: mapITodoToTodo(parentITodo),
        child: todo,
      };

    return {
      child: todo,
    };
  };

export default readTodoAnonymousService;
