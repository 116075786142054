import { Fade, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import FlatButton from 'components/buttons/FlatButton';

import TodoRoots from './todo_roots_list/TodoRootsList';

const Dashboard = () => {
  return (
    <Fade in={true} unmountOnExit>
      <Grid container direction="column">
        <Grid item container>
          <Link
            to={Routes.APP.TODO.MENU.ROOT}
            style={{ textDecoration: 'none' }}
          >
            <FlatButton
              textTransform="none"
              startIcon={<i className="fas fa-sitemap"></i>}
            >
              Tree View
            </FlatButton>
          </Link>
        </Grid>

        <Grid item container style={{ marginTop: 24 }}>
          <TodoRoots></TodoRoots>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default Dashboard;
