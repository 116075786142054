import Dexie from 'dexie';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

class AnonymousStorage {
  ref: Dexie;
  todos: Dexie.Table<ITodo, number>;

  constructor() {
    this.ref = new Dexie('todo_anonymous_db');
    this.ref.version(1).stores({
      todos: '++id, content',
    });

    this.todos = this.ref.table('todos');
  }
}

export const mapITodoToTodo = (iTodo: ITodo): Todo => {
  const todo: Todo = {
    id: iTodo.id!.toString(),
    content: iTodo.content,
    completed: iTodo.completed || false,
    childrenCount: iTodo.childrenCount || 0,
    completedChildren: iTodo.completedChildren || 0,
    completedTimestamp: iTodo.completedTimestamp,
  };

  if (iTodo.parentID) todo.parentID = iTodo.parentID.toString();

  return todo;
};

export interface ITodo {
  id?: number;
  content: TodoContent;
  completed?: boolean;
  parentID?: number;

  completedTimestamp?: number;
  completedChildren?: number;
  childrenCount?: number;

  archived?: boolean;
}

export default AnonymousStorage;
