import { FunctionComponent } from 'react';
import {
  Button,
  Fade,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import CompleteTodoDate from './CompleteTodoDate';
import useCompleteTodo from './useCompleteTodo';

type StylesProps = {
  completed?: boolean;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    ratio: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      color: 'rgba(0, 0, 0, 0.56)',
    },
    warning: {
      marginLeft: theme.spacing(3),
    },
    completeButton: {
      textTransform: 'none',
      color: (props: StylesProps) =>
        props.completed ? 'rgba(0, 0, 0, 0.88)' : 'rgba(0, 0, 0, 0.56)',
      '&:hover': {
        color: (props: StylesProps) =>
          !props.completed ? 'rgba(0, 0, 0, 0.88)' : 'rgba(0, 0, 0, 0.56)',
      },
    },
  })
);

type Props = {
  todo: Todo;
  tasks: Array<Todo>;
  onTodoCompleted: (todo: Todo) => void;
  completeTodo: (todo: Todo) => Promise<TodoParentChild>;
};
const CompleteTodo: FunctionComponent<Props> = ({
  todo,
  tasks,
  onTodoCompleted,
  completeTodo,
}) => {
  const hasAnyTask = tasks.length > 0;
  const completedTasks = tasks.filter((task) => task.completed).length;
  const { isLoading, onSubmit } = useCompleteTodo(
    todo,
    onTodoCompleted,
    completeTodo
  );
  const hasCompleted = completedTasks === tasks.length;
  const { completed, completedTimestamp } = todo;

  const classes = useStyles({ completed });

  return (
    <Grid container direction="column">
      {hasAnyTask && (
        <Grid item container className={classes.ratio}>
          <Grid item>
            {completedTasks} / {tasks.length}
          </Grid>

          {!hasCompleted && (
            <Grid item className={classes.warning}>
              <Typography variant="body2">Complete the tasks above</Typography>
            </Grid>
          )}
        </Grid>
      )}

      <Grid item container alignItems="center">
        <Grid item style={{ flexGrow: 1, marginRight: 16 }}>
          <Button
            onClick={onSubmit}
            className={classes.completeButton}
            disabled={hasAnyTask && completedTasks < tasks.length}
            startIcon={
              isLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: 'rgba(0, 0, 0, 0.72)' }}
                ></CircularProgress>
              ) : completed ? (
                <CheckCircle></CheckCircle>
              ) : (
                <CheckCircleOutline></CheckCircleOutline>
              )
            }
          >
            {completed ? 'Mark as incomplete' : 'Mark as complete'}
          </Button>
        </Grid>

        <Fade in={completed} unmountOnExit>
          <Grid item>
            <CompleteTodoDate
              timestamp={completedTimestamp!}
            ></CompleteTodoDate>
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
};

export default CompleteTodo;
