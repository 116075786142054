import { Fragment, FunctionComponent, useState, MouseEvent } from 'react';
import {
  Grid,
  Popover,
  makeStyles,
  createStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
    },
    userInfo: {
      padding: theme.spacing(2),
    },
    name: {
      marginBottm: theme.spacing(1),
    },
    logoutButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      padding: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.87)',
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

type Props = {
  email: string;
  name: string;
  renderButton: (
    onClick: (event: MouseEvent<HTMLButtonElement>) => void
  ) => JSX.Element;
};
const ProfileMenu: FunctionComponent<Props> = ({
  email,
  name,
  renderButton,
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>();
  const classes = useStyles();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);

  const onClose = () => setAnchor(null);

  return (
    <Fragment>
      {renderButton(openMenu)}
      <Popover
        className={classes.root}
        open={!!anchor}
        anchorEl={anchor}
        onClose={onClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        elevation={2}
      >
        <Grid container direction="column">
          <Grid item container direction="column" className={classes.userInfo}>
            <Grid item container>
              <Typography variant="body1" className={classes.name}>
                {name}
              </Typography>
            </Grid>

            <Grid item container>
              <Typography variant="body1">{email}</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Divider></Divider>
          </Grid>

          <Grid item container direction="column">
            <Grid item container>
              <Link to={Routes.LOGOUT} className={classes.logoutButton}>
                <ExitToApp className={classes.icon}></ExitToApp>
                Logout
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Fragment>
  );
};

export default ProfileMenu;
