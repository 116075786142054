import { FunctionComponent } from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';

import Todo from 'domain/todo/types/Todo';

import TodoCard from './TodoCard';

import { useTodoFormDialogContext } from '../TodoFormDialogProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(1),
    },
  })
);

type Props = {
  tasks: Array<Todo>;
};
const TasksList: FunctionComponent<Props> = ({ tasks }) => {
  const { router } = useTodoFormDialogContext();
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {tasks.map((task) => (
        <Grid item container key={task.id} className={classes.card}>
          <TodoCard todo={task} onClick={() => router(task.id)}></TodoCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default TasksList;
