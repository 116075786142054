import { useState, useEffect, useRef } from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

type Params = {
  createTodo: (todoContent: TodoContent) => Promise<Todo>;
  onTodoCreated: (todo: Todo) => void;
};
const useTodoAddField = (params: Params) => {
  const { createTodo, onTodoCreated } = params;

  const titleInputRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState<LoadStatus>();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (status !== SUBMIT) return;

    setStatus(LOADING);

    const create = async () => {
      try {
        const todo = await createTodo({
          title,
        });

        setStatus(DONE);
        onTodoCreated(todo);
      } catch (error) {
        setStatus(ERROR);
        setErrorMessage(
          'ToDo creation failed. Check your Internet connection.'
        );

        throw error;
      }
    };
    create();
  }, [createTodo, onTodoCreated, status, title]);

  const onSubmit = () => {
    if (status === SUBMIT || status === LOADING || status === DONE) return;

    if (!title.trim().length) titleInputRef.current?.focus();
    else setStatus(SUBMIT);
  };

  const _setTitle = (title: string) => {
    if (status === SUBMIT || status === LOADING) return;
    setTitle(title);
  };

  return {
    title,
    setTitle: _setTitle,
    onSubmit,
    loading: status === LOADING,
    failed: status === ERROR,
    titleInputRef,
    errorMessage,
  };
};

export default useTodoAddField;
