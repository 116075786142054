import AppMenuSection from './AppMenuSection';

import DashboardBreadcrumbs from '../header/breadcrumbs/paths/DashboardBreadcrumbs';
import Dashboard from '../body/dashboard/Dashboard';

const DashboardSection = () => {
  return (
    <AppMenuSection
      header={<DashboardBreadcrumbs></DashboardBreadcrumbs>}
      body={<Dashboard></Dashboard>}
    ></AppMenuSection>
  );
};

export default DashboardSection;
