import { Grid, Fade } from '@material-ui/core';

import HomeHeader from 'components/header/HomeHeader';

import AppDescription from './AppDescription';

const HomePage = () => {
  return (
    <Grid container direction="column">
      <Grid item container>
        <HomeHeader></HomeHeader>
      </Grid>

      <Fade in={true} mountOnEnter>
        <Grid item container>
          <AppDescription></AppDescription>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default HomePage;
