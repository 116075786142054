import Todo from 'domain/todo/types/Todo';

import useRequest from 'domain/useRequest';

import useTodoTreeContext from '../../provider/useTodoTreeContext';

const useNodeArchiveButton = (todo: Todo) => {
  const { canBeArchived, archive } = useTodoTreeContext();
  const archivable = canBeArchived(todo);

  const { loading, submit } = useRequest(
    async () => {
      await archive.call(todo.id);
    },
    () => {
      archive.onDone(todo.id);
    }
  );

  return {
    archivable,
    loading,
    submit,
  };
};

export default useNodeArchiveButton;
