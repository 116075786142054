import auth from './auth';
import todo from './todo';

import initializeInterceptors from './interceptors';

const api = {
  auth,
  todo,
  init: initializeInterceptors,
};

export default api;
