import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoContentValidationError from 'domain/todo/types/TodoContentValidationError';

import TodoServices from 'services/todo/TodoServices';

import validateTodoUseCase, { hasErrors } from './validateTodoUseCase';

const editTodoUseCase = (todoServices: TodoServices) => (id: string) => async (
  content: TodoContent
): Promise<Todo> => {
  const errors = validateTodoUseCase(content);

  if (hasErrors(errors)) throw new TodoContentValidationError(errors);

  const editedTodo = await todoServices.edit(id)(content);

  return editedTodo;
};

export default editTodoUseCase;
