import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import useRequest from 'domain/useRequest';
import LoginUseCases, {
  EmptyEmail,
  ExpiredCode,
  InvalidEmail,
  UserNotFound,
  WrongCode,
} from 'domain/authentication/usecases/LoginUseCases';

import useCodeInput from 'components/input/code/useCodeInput';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

import delayed from 'utils/delayed';

const FORM_STEP = 'FORM_STEP';
const CODE_STEP = 'CODE_STEP';
type LoginStep = typeof FORM_STEP | typeof CODE_STEP;

const useLoginCard = () => {
  const { login } = useAuthentication();
  const history = useHistory();

  const [step, setStep] = useState<LoginStep>(FORM_STEP);

  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState('');

  const [loginToken, setLoginToken] = useState('');
  const { digits, setDigits, resetDigits } = useCodeInput();
  const [submitError, setSubmitError] = useState('');

  const FormRequest = useRequest(
    async () => {
      setValidationError('');
      setSubmitError('');

      try {
        LoginUseCases.validate(email);

        const token = await delayed(LoginUseCases.sendCode(email));
        return token;
      } catch (error) {
        switch (error.constructor) {
          case EmptyEmail:
            setValidationError('Email should not be empty');
            break;

          case InvalidEmail:
            setSubmitError('Invalid email');
            break;

          case UserNotFound:
            setSubmitError('This email is not registered');
            break;

          default:
            setSubmitError('Login failed. Try again');
        }

        throw error;
      }
    },
    (token) => {
      setLoginToken(token);
      setStep(CODE_STEP);
    }
  );

  const ConfirmCodeRequest = useRequest(
    async () => {
      setSubmitError('');

      try {
        const code = digits.join('');
        const user = await delayed(LoginUseCases.confirmCode(loginToken)(code));
        return user;
      } catch (error) {
        resetDigits();

        switch (error.constructor) {
          case WrongCode:
            setSubmitError('Wrong code');
            break;

          case ExpiredCode:
            setSubmitError('Code expired');
            setStep(FORM_STEP);
            break;

          default:
            setSubmitError('Code confirmation failed. Try again');
        }

        throw error;
      }
    },
    (user) => {
      login(user);
      history.push(Routes.APP.TODO.MENU.ROOT);
    }
  );

  const submitForm = () => FormRequest.submit();
  const confirmCode = () => ConfirmCodeRequest.submit();

  return {
    formStep: step === FORM_STEP,

    email,
    setEmail,
    validationError,
    submitForm,
    FormRequest,

    digits,
    setDigits,
    confirmCode,
    ConfirmCodeRequest,

    submitError,
  };
};

export default useLoginCard;
