import useTodoList from 'pages/app/providers/todo_list/useTodoList';

const useTodoRoots = () => {
  const { roots } = useTodoList();

  return {
    roots,
  };
};

export default useTodoRoots;
