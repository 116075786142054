import { Grid, makeStyles, createStyles } from '@material-ui/core';

import Routes from 'config/Routes';

import LoginCardOption from './LoginCardOption';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

const LoginCardOptions = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container>
        <LoginCardOption
          icon={<i className="fas fa-user-secret"></i>}
          route={Routes.AUTH.LOGIN.ANONYMOUS}
        ></LoginCardOption>
      </Grid>
    </Grid>
  );
};

export default LoginCardOptions;
