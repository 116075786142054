import { Switch, Route } from 'react-router-dom';

import Routes from 'config/Routes';

import EditTodoDialog from './edit/EditTodoDialog';

const TodoMenuDialog = () => {
  return (
    <Switch>
      <Route path={Routes.APP.TODO.MENU.FORM}>
        <EditTodoDialog></EditTodoDialog>
      </Route>
    </Switch>
  );
};

export default TodoMenuDialog;
