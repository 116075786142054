import User from 'domain/authentication/types/User';

import api from 'services/api';

const LoginServices = {
  sendCode: async (email: string): Promise<string> => {
    const token = await api.auth.login.sendCode(email);
    return token;
  },
  confirmCode:
    (token: string) =>
    async (code: string): Promise<User> => {
      const user = await api.auth.login.confirmCode(token)(code);
      return user;
    },
};

export default LoginServices;
