import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import Todo from 'domain/todo/types/Todo';

import useTodoList from 'pages/app/providers/todo_list/useTodoList';

import TodoTree from './tree/TodoTree';

type Props = {
  todos: Array<Todo>;
};
const TodoListTree: FC<Props> = ({ todos }) => {
  const history = useHistory();
  const {
    addEdge,
    deleteEdge,
    createTodoChild,
    completeTodo,
    canBeArchived,
    archive,
  } = useTodoList();

  const openTodo = (todoID: string) =>
    history.push(Routes.APP.TODO.MENU.form(todoID));

  return (
    <TodoTree
      todos={todos}
      addEdge={addEdge}
      deleteEdge={deleteEdge}
      createTodoChild={createTodoChild}
      completeTodo={completeTodo}
      openTodo={openTodo}
      archive={archive}
      canBeArchived={canBeArchived}
    ></TodoTree>
  );
};

export default TodoListTree;
