import RegisterForm from 'domain/authentication/types/RegisterForm';
import User from 'domain/authentication/types/User';

import api from 'services/api';

const RegisterServices = {
  sendCode: async (form: RegisterForm): Promise<string> => {
    const token = await api.auth.register.sendCode(form);
    return token;
  },
  confirmCode:
    (token: string) =>
    async (code: string): Promise<User> => {
      const user = await api.auth.register.confirmCode(token)(code);
      return user;
    },
};

export default RegisterServices;
