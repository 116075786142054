import { Grid, Typography, Zoom } from '@material-ui/core';

const NotFound = () => {
  return (
    <Zoom in={true}>
      <Grid container direction="column">
        <Grid item container justify="center">
          <Typography variant="h1">
            <b>404</b>
          </Typography>
        </Grid>

        <Grid item container justify="center" style={{ marginTop: 16 }}>
          <Typography variant="h6">Page not found</Typography>
        </Grid>
      </Grid>
    </Zoom>
  );
};

export default NotFound;
