import AuthenticationType, {
  ANONYMOUS,
  EMAIL,
} from 'domain/authentication/types/AuthenticationType';
import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import addEdgeUseCase from './addEdgeUseCase';
import archiveTodoUseCase from './archiveTodoUseCase';
import clearTodosUseCase from './clearTodosUseCase';
import completeTodoUseCase from './completeTodoUseCase';
import createTodoUseCase from './createTodoUseCase';
import createTodoChildUseCase from './createTodoChildUseCase';
import deleteEdgeUseCase from './deleteEdgeUseCase';
import editTodoUseCase from './editTodoUseCase';
import listTodosUseCase from './listTodosUseCase';
import readTodoUseCase from './readTodoUseCase';

import TodoServices from 'services/todo/TodoServices';
import anonymousTodoServices from 'services/todo/anonymous';
import emailTodoServices from 'services/todo/email';

class TodoUseCases {
  addEdge: (todoID: string, parentID: string) => Promise<TodoParentChild>;
  archive: (todoID: string) => Promise<void>;
  clear: () => Promise<void>;
  complete: (todo: Todo) => Promise<TodoParentChild>;
  create: (todoContent: TodoContent) => Promise<Todo>;
  createChild: (
    parent: Todo
  ) => (todoContent: TodoContent) => Promise<TodoParentChild>;
  deleteEdge: (todoID: string) => Promise<TodoParentChild>;
  edit: (id: string) => (todoContent: TodoContent) => Promise<Todo>;
  list: () => Promise<Array<Todo>>;
  read: (id: string) => Promise<TodoParentChild>;

  constructor(todoServices: TodoServices) {
    this.addEdge = addEdgeUseCase(todoServices);
    this.archive = archiveTodoUseCase(todoServices);
    this.clear = clearTodosUseCase(todoServices);
    this.complete = completeTodoUseCase(todoServices);
    this.create = createTodoUseCase(todoServices);
    this.createChild = createTodoChildUseCase(todoServices);
    this.deleteEdge = deleteEdgeUseCase(todoServices);
    this.edit = editTodoUseCase(todoServices);
    this.list = listTodosUseCase(todoServices);
    this.read = readTodoUseCase(todoServices);
  }
}

// Exporting the usecases below makes hot reload to work when services are changed
export const todoAnonymousUseCases = new TodoUseCases(anonymousTodoServices);
export const todoEmailUseCases = new TodoUseCases(emailTodoServices);

export const TodoUseCasesFactory = (
  authenticationType?: AuthenticationType
) => {
  if (authenticationType === ANONYMOUS) return todoAnonymousUseCases;
  if (authenticationType === EMAIL) return todoEmailUseCases;

  return;
};

export default TodoUseCases;
