import { FunctionComponent } from 'react';
import {
  Fade,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { TextAlignProperty } from 'csstype';

const StyledTextField = withStyles((theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '12px 16px',
        backgroundColor: theme.palette.grey[50],
        borderRadius: '8px',
      },
      '& .MuiOutlinedInput-root': {
        color: theme.palette.text.primary,
        borderRadius: '8px',
      },
      '& .MuiInputBase-multiline': {
        padding: 0,
        lineHeight: 1.5,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    error: {
      marginTop: theme.spacing(0.5),
      textAlign: 'end',
      color: theme.palette.error.main,
    },
  })
);

type Props = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  fullWidth?: boolean;
  error?: string;
  multiline?: boolean;
  hideCursor?: boolean;
  maxLength?: number;
  textAlign?: TextAlignProperty;
  padding?: string;
};
const MainTextField: FunctionComponent<Props> = ({
  value,
  onChange,
  label = '',
  fullWidth,
  error,
  multiline = false,
  hideCursor = false,
  maxLength,
  textAlign,
  padding,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.label} variant="body1">
          {label}
        </Typography>
      )}

      <StyledTextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth={fullWidth}
        variant="outlined"
        multiline={multiline}
        inputProps={{
          maxLength: maxLength || 'inherit',
          style: {
            caretColor: hideCursor ? 'transparent' : 'inherit',
            textAlign: textAlign || 'inherit',
            padding: padding ?? '12px 16px',
          },
        }}
      ></StyledTextField>

      <Fade in={!!error} unmountOnExit>
        <Typography variant="body2" className={classes.error}>
          <b>
            <i>{error}</i>
          </b>
        </Typography>
      </Fade>
    </div>
  );
};

export default MainTextField;
