export class Graph<T> {
  roots: Array<Node<T>>;

  constructor(nodes: Array<Node<T>>) {
    this.roots = nodes.filter((node) => !node.parent);
  }
}

export class Node<T> {
  data: T;
  children: Array<Node<T>>;
  parent?: Node<T>;

  constructor(data: T) {
    this.data = data;
    this.children = [];
  }
}
