import TodoServices from 'services/todo/TodoServices';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

const deleteEdgeUseCase =
  (todoServices: TodoServices) =>
  async (todoID: string): Promise<TodoParentChild> => {
    const parentChild = await todoServices.deleteEdge(todoID);

    return parentChild;
  };

export default deleteEdgeUseCase;
