import { FunctionComponent } from 'react';
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import TodoFormDialog from 'components/todo/form/dialog/TodoFormDialog';

import useTodoList from 'pages/app/providers/todo_list/useTodoList';

import TodoDialog from '../TodoDialog';
import useEditTodoDialog from './useEditTodoDialog';

const withStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      color: theme.palette.grey[800],
    },
  })
);

const EditTodoDialog = () => {
  const history = useHistory();
  const { loading, parentChild, failed, notFound, tryAgain } =
    useEditTodoDialog();
  const { editTodo, completeTodo, createTodoChild } = useTodoList();
  const classes = withStyles();

  const router = (todoID: string) =>
    history.push(Routes.APP.TODO.MENU.form(todoID));

  if (loading)
    return (
      <TodoDialog
        renderBody={() => (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <Grid item>
              <CircularProgress className={classes.loading}></CircularProgress>
            </Grid>
          </Grid>
        )}
      ></TodoDialog>
    );

  if (failed)
    return (
      <TodoDialog
        renderBody={() =>
          notFound ? (
            <NotFoundWarning></NotFoundWarning>
          ) : (
            <ErrorWarning tryAgain={tryAgain}></ErrorWarning>
          )
        }
      ></TodoDialog>
    );

  return (
    <TodoDialog
      renderBody={() => (
        <TodoFormDialog
          parentChild={parentChild}
          router={router}
          editTodo={editTodo}
          completeTodo={completeTodo}
          createTodoChild={createTodoChild}
        ></TodoFormDialog>
      )}
    ></TodoDialog>
  );
};

type ErrorWarningProps = {
  tryAgain: () => void;
};
const ErrorWarning: FunctionComponent<ErrorWarningProps> = ({ tryAgain }) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{ height: '100%' }}
    >
      <Grid item container justify="center">
        <Typography variant="h2">500</Typography>
      </Grid>

      <Grid
        item
        container
        justify="center"
        style={{ marginTop: 16, color: 'rgba(0, 0, 0, 0.64)' }}
      >
        <Typography variant="body1">
          ToDo load <b>failed</b>
        </Typography>
      </Grid>

      <Grid item container justify="center" style={{ marginTop: 8 }}>
        <Button onClick={tryAgain} style={{ textTransform: 'none' }}>
          <b>Try Again</b>
        </Button>
      </Grid>
    </Grid>
  );
};

const NotFoundWarning = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{ height: '100%' }}
    >
      <Grid item container justify="center">
        <Typography variant="h2">404</Typography>
      </Grid>

      <Grid
        item
        container
        justify="center"
        style={{ marginTop: 16, color: 'rgba(0, 0, 0, 0.64)' }}
      >
        <Typography variant="body1">This ToDo was not found</Typography>
      </Grid>
    </Grid>
  );
};

export default EditTodoDialog;
