import useTodoList from 'pages/app/providers/todo_list/useTodoList';

const useTodoMenu = () => {
  const { todos } = useTodoList();

  return {
    todos,
  };
};

export default useTodoMenu;
