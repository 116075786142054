import { FC } from 'react';
import { Fade } from '@material-ui/core';
import { getBezierPath, getEdgeCenter, NodeProps } from 'react-flow-renderer';

import EdgeMenu from '../../edge/EdgeMenu';
import { EdgeData } from '../../NodeTask';

const NodeEdgeSelected: FC<NodeProps<EdgeData>> = ({ data }) => {
  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    target,
    sourcePosition,
    targetPosition,
  } = data;

  const x = Math.min(sourceX, targetX);
  const y = Math.min(sourceY, targetY);

  const X = Math.max(sourceX, targetX);
  const Y = Math.max(sourceY, targetY);

  const height = Y - y + 200;
  const width = X - x + 200;

  const edgePath = getBezierPath({
    sourceX: sourceX - x,
    sourceY: sourceY - y,
    sourcePosition,
    targetX: targetX - x,
    targetY: targetY - y,
    targetPosition,
  });
  const [centerX, centerY] = getEdgeCenter({
    sourceX: sourceX - x,
    sourceY: sourceY - y,
    targetX: targetX - x,
    targetY: targetY - y,
  });

  return (
    <svg height={height} width={width}>
      <path
        className={`react-flow__edge-path`}
        d={edgePath}
        style={{ stroke: 'rgb(255, 0, 114)' }}
      />
      <Fade in={true} unmountOnExit style={{ pointerEvents: 'visiblePainted' }}>
        <foreignObject
          x={centerX}
          y={centerY}
          height={1}
          width={200}
          style={{ overflow: 'visible' }}
        >
          <EdgeMenu todoID={target}></EdgeMenu>
        </foreignObject>
      </Fade>
    </svg>
  );
};

export default NodeEdgeSelected;
