import TodoContent from 'domain/todo/types/TodoContent';

const areTodoContentsEqualUseCase = (
  contentA: TodoContent,
  contentB: TodoContent
): boolean => {
  if (contentA.title.trim() !== contentB.title.trim()) return false;

  if (!_areDescriptionEqual(contentA.description, contentB.description))
    return false;

  return true;
};

const _areDescriptionEqual = (descriptionA = '', descriptionB = '') =>
  descriptionA.trim() === descriptionB.trim();

export default areTodoContentsEqualUseCase;
