import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const useScreenSize = () => {
  const theme = useTheme();
  const isUpSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return {
    isMobile: !isUpSM,
  };
};

export default useScreenSize;
