import { DONE, ERROR, LOADING } from 'config/LoadStatus';
import { useContext } from 'react';

import { TodoListContext } from './TodoListProvider';

const useTodoList = () => {
  const {
    retry,
    todos,
    status,
    addEdge,
    archive,
    completeTodo,
    createTodo,
    createTodoChild,
    deleteEdge,
    editTodo,
    canBeArchived,

    roots,
  } = useContext(TodoListContext);

  return {
    retry,
    todos,
    loading: status === LOADING,
    done: status === DONE,
    failed: status === ERROR,
    addEdge: addEdge!,
    archive: archive!,
    completeTodo: completeTodo!,
    createTodo: createTodo!,
    createTodoChild: createTodoChild!,
    deleteEdge: deleteEdge!,
    editTodo: editTodo!,
    canBeArchived: canBeArchived!,

    roots: roots!,
  };
};

export default useTodoList;
