import { FC } from 'react';

import MainTextField from 'components/textfield/MainTextField';

type Props = {
  id: number;
  value: string;
  onChange: (value: string) => void;
};
const Digit: FC<Props> = ({ id, value, onChange }) => {
  return (
    <div id={`digit_${id}`}>
      <MainTextField
        value={value}
        onChange={onChange}
        hideCursor
        maxLength={1}
        textAlign="center"
        padding="16px 4px"
      ></MainTextField>
    </div>
  );
};

export default Digit;
