import { useState } from 'react';

const useCodeInput = (length = 6) => {
  const EmptyDigits = Array(length).fill('');
  const [digits, setDigits] = useState<Array<string>>(EmptyDigits);

  const resetDigits = () => setDigits(EmptyDigits);

  return { digits, setDigits, resetDigits };
};

export default useCodeInput;
