import TodoParentChild from 'domain/todo/types/TodoParentChild';

import TodoServices from 'services/todo/TodoServices';

const readTodoUseCase =
  (todoServices: TodoServices) =>
  async (id: string): Promise<TodoParentChild> => {
    const parentChild = await todoServices.read(id);

    return parentChild;
  };

export default readTodoUseCase;
