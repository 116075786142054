import { createContext, FC } from 'react';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';
import TodoArchiveUseCase from 'domain/todo/types/TodoArchiveUseCase';

type TodoTreeContextType = {
  addEdge?: (todoID: string, parentID: string) => Promise<TodoParentChild>;
  deleteEdge?: (todoID: string) => Promise<TodoParentChild>;
  createTodoChild?: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
  completeTodo?: (todo: Todo) => Promise<TodoParentChild>;

  canBeArchived?: (todo: Todo) => boolean;
  archive?: TodoArchiveUseCase;

  openTodo?: (todoID: string) => void;
};

export const TodoTreeContext = createContext<TodoTreeContextType>({});

type Props = {
  addEdge: (todoID: string, parentID: string) => Promise<TodoParentChild>;
  deleteEdge: (todoID: string) => Promise<TodoParentChild>;
  createTodoChild: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
  completeTodo: (todo: Todo) => Promise<TodoParentChild>;

  canBeArchived: (todo: Todo) => boolean;
  archive: TodoArchiveUseCase;

  openTodo: (todoID: string) => void;
};
const TodoTreeProvider: FC<Props> = ({
  children,

  addEdge,
  deleteEdge,
  createTodoChild,
  completeTodo,

  canBeArchived,
  archive,

  openTodo,
}) => {
  return (
    <TodoTreeContext.Provider
      value={{
        addEdge,
        deleteEdge,
        createTodoChild,
        completeTodo,

        canBeArchived,
        archive,

        openTodo,
      }}
    >
      {children}
    </TodoTreeContext.Provider>
  );
};

export default TodoTreeProvider;
