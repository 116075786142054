import { FunctionComponent } from 'react';
import { Grid, Paper } from '@material-ui/core';

import RemoveButton from './RemoveButton';

type Props = {
  todoID: string;
};
const EdgeMenu: FunctionComponent<Props> = ({ todoID }) => {
  return (
    <Paper elevation={4}>
      <Grid container direction="column">
        <Grid item container>
          <RemoveButton todoID={todoID}></RemoveButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EdgeMenu;
