import { FunctionComponent, RefObject, useState } from 'react';
import { TextField } from '@material-ui/core';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
};
const FocusTextField: FunctionComponent<Props> = ({
  value,
  onChange,
  error = '',
  autoFocus = false,
  inputRef,
}) => {
  const [focused, setFocused] = useState(false);

  const hasError = !!error;

  return (
    <TextField
      fullWidth
      InputProps={{ disableUnderline: !hasError && !focused }}
      value={value}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      onChange={(e) => onChange(e.target.value)}
      error={hasError}
      helperText={error}
      multiline
      autoFocus={autoFocus}
      inputRef={inputRef}
    ></TextField>
  );
};

export default FocusTextField;
