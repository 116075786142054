import axios from 'axios';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

const addEdge = async (todoID: string, parentID: string) => {
  const res = await axios.post('/api/todo/add_edge', { todoID, parentID });

  const todoParentChild = res.data as TodoParentChild;
  return todoParentChild;
};

const archive = async (todoID: string) => {
  await axios.post('/api/todo/archive', { todoID });
};

const list = async (): Promise<Array<Todo>> => {
  const res = await axios.post('/api/todo/list');

  const { todos }: { todos: Array<Todo> } = res.data;

  return todos;
};

const create = async (content: TodoContent): Promise<string> => {
  const res = await axios.post('/api/todo/create', {
    content,
  });

  const hash = res.data;

  return hash;
};

const createChild =
  (parentID: string) =>
  async (todoContent: TodoContent): Promise<TodoParentChild> => {
    const res = await axios.post('/api/todo/create_child', {
      parentID,
      todoContent,
    });

    const { child, parent }: { child: Todo; parent: Todo } = res.data;

    return {
      child,
      parent,
    };
  };

const complete = async (todoID: string): Promise<TodoParentChild> => {
  const res = await axios.post('/api/todo/complete', { todoID });

  const todoParentChild = res.data as TodoParentChild;
  return todoParentChild;
};

const deleteEdge = async (todoID: string): Promise<TodoParentChild> => {
  const res = await axios.post('/api/todo/delete_edge', { todoID });

  const todoParentChild = res.data as TodoParentChild;
  return todoParentChild;
};

const edit =
  (id: string) =>
  async (todoContent: TodoContent): Promise<Todo> => {
    const res = await axios.post('/api/todo/edit', {
      todoID: id,
      todoContent,
    });

    const todo = res.data as Todo;

    return todo;
  };

const read = async (id: string): Promise<TodoParentChild> => {
  const res = await axios.post('/api/todo/read', { id });

  const todoParentChild = res.data as TodoParentChild;
  return todoParentChild;
};

const todo = {
  addEdge,
  archive,
  complete,
  create,
  createChild,
  deleteEdge,
  edit,
  list,
  read,
};

export default todo;
