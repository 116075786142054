import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

import Routes from 'config/Routes';

import DashboardSection from './sections/DashboardSection';
import TodoSection from './sections/todo/TodoSection';

import LoadingData from './LoadingData';
import LoadingFailed from './LoadingFailed';

import useAppMenu from './useAppMenu';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: '100%',
    },
    header: {
      padding: theme.spacing(1),
    },
    body: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
  })
);

const AppMenu = () => {
  const { loading, retry, failed } = useAppMenu();
  const classes = useStyles();

  if (loading) return <LoadingData></LoadingData>;

  if (failed) return <LoadingFailed retry={retry}></LoadingFailed>;

  return (
    <Grid container direction="column" className={classes.root}>
      <Switch>
        <Route path={Routes.APP.TODO.ROOT}>
          <TodoSection></TodoSection>
        </Route>

        <Route path={Routes.APP.ROOT}>
          <DashboardSection></DashboardSection>
        </Route>
      </Switch>
    </Grid>
  );
};

export default AppMenu;
