import { FunctionComponent } from 'react';
import { Paper, createStyles, makeStyles } from '@material-ui/core';

type StyleProps = {
  dragging?: boolean;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: 290,
      borderRadius: theme.spacing(1),
      transform: (props: StyleProps) =>
        props.dragging ? 'rotate(2.5deg)' : 'none',
    },
    menu: {
      position: 'absolute',
      marginTop: -theme.spacing(1),
      zIndex: -1,
    },
    menuPaper: {
      width: 290,
    },
    icon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primary.main,
    },
  })
);

type Props = {
  selected: boolean;
  dragging?: boolean;
};
const PaperCard: FunctionComponent<Props> = ({
  children,
  selected,
  dragging,
}) => {
  const classes = useStyles({ dragging });

  return (
    <Paper className={classes.root} elevation={selected ? 1 : 5}>
      {children}
    </Paper>
  );
};

export default PaperCard;
