import Todo from 'domain/todo/types/Todo';

import TodoServices from 'services/todo/TodoServices';

const listTodosUseCase =
  (todoServices: TodoServices) => async (): Promise<Array<Todo>> => {
    const todos = await todoServices.list();

    return todos;
  };

export default listTodosUseCase;
