import { useEffect, useState } from 'react';

import LoadStatus, { DONE, ERROR, LOADING } from 'config/LoadStatus';

import { NotFoundError } from 'domain/errors';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import useUseCases from 'pages/providers/usecases/useUseCases';

const useTodoFormRead = (id: string) => {
  const { todoUseCases } = useUseCases();

  const [lastID, setLastID] = useState(id);
  const [parentChild, setParentChild] = useState<TodoParentChild | null>(null);
  const [status, setStatus] = useState<LoadStatus>();
  const [submit, setSubmit] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const reloadTodoWhenIDChanges = () => {
      if (id === lastID) return;
      setLastID(id);
      setSubmit(true);
    };

    reloadTodoWhenIDChanges();
  }, [id, lastID]);

  useEffect(() => {
    if (!submit) return;

    setSubmit(false);
    setStatus(LOADING);

    const read = async () => {
      try {
        const parentChild = await todoUseCases.read(id);

        setParentChild(parentChild);
        setStatus(DONE);
      } catch (error) {
        setNotFound(error instanceof NotFoundError);
        setStatus(ERROR);
      }
    };
    read();
  }, [id, todoUseCases, submit]);

  const tryAgain = () => {
    if (submit || status === DONE) return;
    setSubmit(true);
  };

  return {
    parentChild,
    loading: status === LOADING,
    done: status === DONE,
    failed: status === ERROR,
    notFound,
    tryAgain,
  };
};

export default useTodoFormRead;
