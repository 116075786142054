import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import api from 'services/api';

const createTodoEmailService = async (content: TodoContent): Promise<Todo> => {
  const hash = await api.todo.create(content);

  return {
    id: hash,
    content,
  };
};

export default createTodoEmailService;
