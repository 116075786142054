import { FunctionComponent } from 'react';
import {
  Button,
  Grid,
  Typography,
  Grow,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    button: {
      textTransform: 'none',
      color: 'rgba(0, 0, 0, 0.40)',
      backgroundColor: `${theme.palette.grey[400]}64`,
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.40)',
    },
  })
);

type Props = {
  showAddButton: boolean;
  onAddButtonClick: () => void;
};
const TodoListHeader: FunctionComponent<Props> = ({
  showAddButton,
  onAddButtonClick,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item className={classes.title}>
        <Typography variant="h6">
          <b>Your ToDo's</b>
        </Typography>
      </Grid>

      <Grow in={showAddButton}>
        <Grid item>
          <Button
            onClick={onAddButtonClick}
            startIcon={<Add className={classes.icon}></Add>}
            className={classes.button}
          >
            <b>Add ToDo</b>
          </Button>
        </Grid>
      </Grow>
    </Grid>
  );
};

export default TodoListHeader;
