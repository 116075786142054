import { FC } from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

import MainTextField from 'components/textfield/MainTextField';
import SubmitButton from 'components/buttons/SubmitButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);

type Props = {
  email: string;
  setEmail: (email: string) => void;
  validationError: string;
  submitError: string;
  submitForm: () => void;
  loading: boolean;
};
const LoginFormStep: FC<Props> = ({
  email,
  setEmail,
  validationError,
  submitForm,
  submitError,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container>
        <MainTextField
          value={email}
          onChange={setEmail}
          label="Email"
          fullWidth
          error={validationError}
        ></MainTextField>
      </Grid>

      <Grid item container justify="flex-end" style={{ marginTop: 24 }}>
        <Typography variant="body2" className={classes.error}>
          <b>
            <i>{submitError}</i>
          </b>
        </Typography>
      </Grid>

      <Grid item container style={{ marginTop: 24 }}>
        <SubmitButton
          onClick={submitForm}
          fullWidth
          isLoading={loading}
          showIcon={false}
        >
          <b>SEND CODE TO EMAIL</b>
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

export default LoginFormStep;
