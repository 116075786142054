import { FC } from 'react';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { TextAlignProperty } from 'csstype';

import AppName from 'config/AppName';

type StyleProps = {
  textAlign: TextAlignProperty;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: (props: StyleProps) => props.textAlign,
      fontFamily: 'Sriracha, cursive',
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  })
);

type Props = {
  textAlign?: TextAlignProperty;
};
const AppTitle: FC<Props> = ({ textAlign = 'center' }) => {
  const classes = useStyles({ textAlign });

  return (
    <Typography variant="h3" className={classes.title}>
      <b>{AppName}</b>
    </Typography>
  );
};

export default AppTitle;
