import User from 'domain/authentication/types/User';

import UserStorageService from 'services/authentication/storage/user';

const UserUseCases = {
  persist: (user: User) => UserStorageService.save(user),
  read: (): User | null => UserStorageService.read(),
};

export default UserUseCases;
