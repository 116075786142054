import { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';

import AppName from 'config/AppName';

import RaisedButton from 'components/buttons/RaisedButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(1),
    },
    error: {
      marginTop: theme.spacing(2),
    },
  })
);

type Props = {
  loading: boolean;
  done: boolean;
  error: boolean;
  retry: () => void;
};
const LogoutPageBody: FunctionComponent<Props> = ({
  loading,
  done,
  error,
  retry,
}) => {
  const classes = useStyles();

  const _text = () => {
    if (done) return `Thanks for using ${AppName}`;
    if (loading) return 'Logging out...';
    return 'Logout has failed';
  };

  return (
    <Grid container direction="column">
      <Grid item container justify="center" className={classes.title}>
        <Typography variant="h4">{_text()}</Typography>
      </Grid>

      {error && (
        <Grid item container justify="center" className={classes.error}>
          <RaisedButton onClick={retry}>Try Again</RaisedButton>
        </Grid>
      )}

      {done && (
        <Grid item container justify="center">
          <Typography variant="h6">You are logged out</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LogoutPageBody;
