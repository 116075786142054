import { createContext, FunctionComponent, useState, useCallback } from 'react';

import AuthenticationType, {
  UNAUTHENTICATED,
  ANONYMOUS,
  EMAIL,
} from 'domain/authentication/types/AuthenticationType';
import User from 'domain/authentication/types/User';

import loginAnonymousUseCase from 'domain/authentication/usecases/loginAnonymousUseCase';
import logoutUseCase from 'domain/authentication/usecases/logoutUseCase';
import UserUseCases from 'domain/authentication/usecases/UserUseCases';

type AuthenticationContextType = {
  isAuthenticated: boolean;
  user: User | null;
  login: (user: User) => Promise<void>;
  loginAnonymous: (name: string) => Promise<void>;
  logout: () => Promise<void>;
  authenticationType?: AuthenticationType;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isAuthenticated: false,
  user: null,
  login: async () => {},
  loginAnonymous: async () => {},
  logout: async () => {},
});

type Props = {};
const AuthenticationProvider: FunctionComponent<Props> = ({ children }) => {
  const [user, setUser] = useState(UserUseCases.read());

  const login = async (user: User) => {
    setUser(user);
    UserUseCases.persist(user);
  };

  const loginAnonymous = useCallback(async (name: string) => {
    const anonymousUser = loginAnonymousUseCase(name);
    await login(anonymousUser);
  }, []);

  const logout = useCallback(async () => {
    await logoutUseCase();
    setUser(null);
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        login,
        loginAnonymous,
        logout,
        authenticationType: !user
          ? UNAUTHENTICATED
          : user.anonymous
          ? ANONYMOUS
          : EMAIL,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
