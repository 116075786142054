import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import RegisterForm, {
  RegisterFormErrors,
} from 'domain/authentication/types/RegisterForm';
import RegisterUseCases, {
  EmailAlreadyPicked,
  ExpiredCode,
  InvalidEmail,
  WrongCode,
} from 'domain/authentication/usecases/RegisterUseCases';
import useRequest from 'domain/useRequest';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

import useCodeInput from 'components/input/code/useCodeInput';

import delayed from 'utils/delayed';

const initialRegisterForm: RegisterForm = {
  email: '',
  name: '',
};

const FORM_STEP = 'FORM_STEP';
const CODE_STEP = 'CODE_STEP';
type RegisterStep = typeof FORM_STEP | typeof CODE_STEP;

const useRegisterPage = () => {
  const { login } = useAuthentication();
  const history = useHistory();

  const [step, setStep] = useState<RegisterStep>(FORM_STEP);

  const [form, setForm] = useState<RegisterForm>(initialRegisterForm);
  const [formErrors, setFormErrors] = useState<RegisterFormErrors | null>(null);

  const [registerToken, setRegisterToken] = useState('');
  const { digits, setDigits, resetDigits } = useCodeInput();

  const [submitError, setSubmitError] = useState('');

  const FormRequest = useRequest(
    async () => {
      setSubmitError('');

      try {
        const token = await delayed(RegisterUseCases.sendCode(form));
        return token;
      } catch (error) {
        switch (error.constructor) {
          case InvalidEmail:
            setSubmitError('Invalid email');
            break;

          case EmailAlreadyPicked:
            setSubmitError('Email already picked');
            break;

          default:
            setSubmitError('Registration failed. Try again');
        }

        throw error;
      }
    },
    (token) => {
      setRegisterToken(token);
      setStep(CODE_STEP);
    }
  );

  const ConfirmCodeRequest = useRequest(
    async () => {
      setSubmitError('');

      try {
        const code = digits.join('');
        const user = await delayed(
          RegisterUseCases.confirmCode(registerToken)(code)
        );
        return user;
      } catch (error) {
        resetDigits();

        switch (error.constructor) {
          case WrongCode:
            setSubmitError('Wrong code');
            break;

          case ExpiredCode:
            setSubmitError('Code expired');
            setStep(FORM_STEP);
            break;

          default:
            setSubmitError('Code confirmation failed. Try again');
        }

        throw error;
      }
    },
    (user) => {
      login(user);
      history.push(Routes.APP.ROOT);
    }
  );

  const submitForm = () => {
    setFormErrors(null);

    const errors = RegisterUseCases.validate(form);

    if (!errors) FormRequest.submit();
    else setFormErrors(errors);
  };

  const submitCode = () => ConfirmCodeRequest.submit();

  return {
    formStep: step === FORM_STEP,

    form,
    setForm,
    submitForm,
    formErrors,
    FormRequest,

    digits,
    setDigits,
    submitCode,
    ConfirmCodeRequest,

    submitError,
  };
};

export default useRegisterPage;
