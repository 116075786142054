import TodoParentChild from 'domain/todo/types/TodoParentChild';

import api from 'services/api';

const completeTodoEmailService = async (
  todoID: string
): Promise<TodoParentChild> => {
  const todoParentChild = await api.todo.complete(todoID);
  return todoParentChild;
};

export default completeTodoEmailService;
