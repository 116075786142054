import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import FlatButton from 'components/buttons/FlatButton';

type Props = {
  archive: boolean;
  submit: () => void;
};
const NodeArchiveMenuBody: FC<Props> = ({ archive, submit }) => {
  const cannotArchive = !archive;
  const canArchive = archive;

  return (
    <Grid container direction="column">
      {cannotArchive && (
        <Grid item container>
          <Typography
            variant="body1"
            style={{
              textAlign: 'center',
              padding: `0 24px`,
              lineHeight: 1.5,
              paddingBottom: 16,
            }}
          >
            You must <b>delete</b> all edges connected to this ToDo before
            archiving it
          </Typography>
        </Grid>
      )}

      {canArchive && (
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="body1">Are you sure ?</Typography>
          </Grid>

          <Grid item>
            <FlatButton onClick={submit}>YES</FlatButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default NodeArchiveMenuBody;
