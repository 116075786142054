import { Switch, Route } from 'react-router-dom';

import Routes from 'config/Routes';

import LoginCard from './card/LoginCard';
import LoginAnonymous from './anonymous/LoginAnonymous';
import NotFound from 'components/not_found/NotFound';

const LoginPage = () => {
  return (
    <Switch>
      <Route path={Routes.AUTH.LOGIN.ROOT} exact>
        <LoginCard></LoginCard>
      </Route>

      <Route path={Routes.AUTH.LOGIN.ANONYMOUS}>
        <LoginAnonymous></LoginAnonymous>
      </Route>

      <Route>
        <NotFound></NotFound>
      </Route>
    </Switch>
  );
};

export default LoginPage;
