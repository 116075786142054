import { Fragment, useState } from 'react';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoParentChild from 'domain/todo/types/TodoParentChild';
import TodoArchiveUseCase from 'domain/todo/types/TodoArchiveUseCase';

import TodoDialogBase from 'pages/app/menu/body/todo/menu/dialog/TodoDialogBase';
import TodoFormDialog from '../form/dialog/TodoFormDialog';

import TodoTree from '../list/tree/TodoTree';

let globalID = 10;
const initialTodos: Array<Todo> = [
  {
    id: '1',
    content: {
      title: `ToDo's`,
    },
    childrenCount: 3,
  },
  {
    id: '2',
    parentID: '1',
    content: {
      title: 'ToDo #1',
    },
    childrenCount: 3,
  },
  {
    id: '3',
    parentID: '1',
    content: {
      title: 'ToDo #2',
    },
  },
  {
    id: '4',
    parentID: '1',
    content: {
      title: 'ToDo #3',
    },
  },
  {
    id: '5',
    parentID: '2',
    content: {
      title: 'Subtask #1',
    },
  },
  {
    id: '6',
    parentID: '2',
    content: {
      title: 'Subtask #2',
    },
  },
  {
    id: '7',
    parentID: '2',
    content: {
      title: 'Subtask #3',
    },
  },
];

const Hero = () => {
  const [todos, setTodos] = useState<Array<Todo>>(initialTodos);
  const [openedTodo, setOpenedTodo] = useState<TodoParentChild | null>(null);

  const openDialog = (todoID: string) => {
    const todo = todos.find((node) => node.id === todoID)!;
    const parent = todos.find((node) => node.id === todo.parentID);

    const children = todos.filter((node) => node.parentID === todoID);

    setOpenedTodo({
      child: { ...todo, children },
      parent,
    });
  };
  const closeDialog = () => setOpenedTodo(null);

  const createTodoChild =
    (parent: Todo) =>
    async (content: TodoContent): Promise<Todo> => {
      const newID = (++globalID).toString();
      const todo: Todo = {
        id: newID,
        parentID: parent.id,
        content,
      };
      const updatedParent: Todo = {
        ...parent,
        childrenCount: (parent.completedChildren || 0) + 1,
      };

      setTodos(
        todos
          .concat(todo)
          .map((todo) => (todo.id === updatedParent.id ? updatedParent : todo))
      );

      return todo;
    };

  const completeTodo = async (todo: Todo): Promise<TodoParentChild> => {
    const parentTodo = todos.find((node) => node.id === todo.parentID);
    const updatedTodo: Todo = {
      ...todo,
      completed: !todo.completed,
      completedTimestamp: Date.now(),
    };
    if (todo.completed) delete updatedTodo.completedTimestamp;
    const updatedParent: Todo | null = parentTodo
      ? {
          ...parentTodo,
          completedChildren:
            (parentTodo.completedChildren || 0) + (!todo.completed ? +1 : -1),
        }
      : null;

    setTodos(
      todos.map((node) => {
        if (node.id === updatedTodo.id) return updatedTodo;
        if (node.id === updatedParent?.id) return updatedParent;
        return node;
      })
    );

    if (updatedParent)
      return {
        child: updatedTodo,
        parent: updatedParent,
      };

    return {
      child: updatedTodo,
    };
  };

  const addEdge = async (
    todoID: string,
    parentID: string
  ): Promise<TodoParentChild> => {
    const todo = todos.find((node) => node.id === todoID)!;
    const parent = todos.find((node) => node.id === parentID)!;

    if (todo.parentID) throw new Error('invalid_edge');
    if (parent.completed) throw new Error('invalid_edge');

    const updatedTodo: Todo = {
      ...todo,
      parentID: parentID,
    };
    const updatedParent: Todo = {
      ...parent,
      childrenCount: (parent.childrenCount || 0) + 1,
      completedChildren:
        (parent.completedChildren || 0) + (todo.completed ? +1 : 0),
    };

    setTodos(
      todos.map((node) => {
        if (node.id === todo.id) return updatedTodo;
        if (node.id === parent.id) return updatedParent;
        return node;
      })
    );

    return { child: updatedTodo, parent: updatedParent };
  };

  const deleteEdge = async (todoID: string): Promise<TodoParentChild> => {
    const todo = todos.find((node) => node.id === todoID);
    const parent = todos.find((node) => node.id === todo?.parentID);

    const updatedTodo: Todo = {
      ...todo!,
    };
    delete updatedTodo.parentID;

    const updatedParent: Todo | null = parent
      ? {
          ...parent,
          childrenCount: (parent.childrenCount || 0) - 1,
          completedChildren:
            (parent.completedChildren || 0) - (todo?.completed ? -1 : 0),
        }
      : null;

    setTodos(
      todos.map((node) => {
        if (node.id === updatedTodo.id) return updatedTodo;
        if (node.id === updatedParent?.id) return updatedParent;
        return node;
      })
    );

    if (updatedParent)
      return {
        child: updatedTodo,
        parent: updatedParent,
      };

    return {
      child: updatedTodo,
    };
  };

  const editTodo =
    (id: string) =>
    async (content: TodoContent): Promise<Todo> => {
      const todo = todos.find((node) => node.id === id)!;
      const updatedTodo: Todo = {
        ...todo,
        content,
      };

      setTodos(todos.map((node) => (node.id === id ? updatedTodo : node)));

      return updatedTodo;
    };

  const canBeArchived = (todo: Todo) => {
    const parent = todos.find((node) => node.id === todo.parentID);
    if (!!parent) return false;

    const anyChild = todos.find((node) => node.parentID === todo.id);
    if (!!anyChild) return false;

    return true;
  };

  const archive: TodoArchiveUseCase = {
    call: async (todoID: string) => {},
    onDone: (todoID: string) =>
      setTodos(todos.filter((node) => node.id !== todoID)),
  };

  return (
    <Fragment>
      <TodoTree
        todos={todos}
        addEdge={addEdge}
        deleteEdge={deleteEdge}
        createTodoChild={createTodoChild}
        completeTodo={completeTodo}
        openTodo={openDialog}
        canBeArchived={canBeArchived}
        archive={archive}
      ></TodoTree>

      <TodoDialogBase open={!!openedTodo} close={closeDialog}>
        {openedTodo && (
          <TodoFormDialog
            key={openedTodo.child.id}
            parentChild={openedTodo}
            router={openDialog}
            editTodo={editTodo}
            completeTodo={completeTodo}
            createTodoChild={createTodoChild}
          ></TodoFormDialog>
        )}
      </TodoDialogBase>
    </Fragment>
  );
};

export default Hero;
