import { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ArchiveOutlined } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';

import ItemButton from 'components/buttons/ItemButton';

import NodeArchiveMenu from './menu/NodeArchiveMenu';
import useNodeArchiveButton from './useNodeArchiveButton';

type Props = {
  todo: Todo;
};
const NodeArchiveButton: FC<Props> = ({ todo }) => {
  const { archivable, submit, loading } = useNodeArchiveButton(todo);

  return (
    <NodeArchiveMenu
      archive={archivable}
      submit={submit}
      render={(openMenu) => {
        return (
          <ItemButton
            label="Archive"
            onClick={openMenu}
            icon={
              loading ? (
                <CircularProgress
                  size={18}
                  style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                ></CircularProgress>
              ) : (
                <ArchiveOutlined></ArchiveOutlined>
              )
            }
          ></ItemButton>
        );
      }}
    ></NodeArchiveMenu>
  );
};

export default NodeArchiveButton;
