import { Fragment, FunctionComponent, useMemo, useContext } from 'react';
import {
  NodeProps,
  Handle,
  Position,
  useStoreActions,
} from 'react-flow-renderer';
import {
  Fade,
  Collapse,
  Grid,
  Icon,
  Paper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Launch, CheckCircle } from '@material-ui/icons';

import Todo from 'domain/todo/types/Todo';

import ItemButton from 'components/buttons/ItemButton';

import { DrawContext } from '../Draw';

import PaperCard from './PaperCard';
import AddTaskItemButton from './AddTaskItemButton';
import NodeCompleteButton from './complete/NodeCompleteButton';
import NodeArchiveButton from './archive/NodeArchiveButton';

import useTodoTreeContext from '../provider/useTodoTreeContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      position: 'absolute',
      marginTop: -theme.spacing(1),
      zIndex: -1,
    },
    menuPaper: {
      width: 290,
    },
    icon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primary.main,
    },
    handle: {
      width: 8,
      height: 8,
      backgroundColor: 'rgb(255, 0, 114)',
      border: '1px solid #fff',
    },
  })
);

const NodeCard: FunctionComponent<NodeProps<Todo>> = ({
  data,
  selected,
  isDragging,
}) => {
  const classes = useStyles();
  const { openAddCard, isTaskOpened, started } = useContext(DrawContext);
  const unselectNode = useStoreActions(
    (actions) => () => actions.setSelectedElements([])
  );
  const { openTodo } = useTodoTreeContext();

  const { content, completed, completedChildren, childrenCount } = data;
  const { title } = content;

  const menuOpen = selected && !isDragging;
  const canBeCompleted = !childrenCount || completedChildren === childrenCount;

  const isTaskOpen = useMemo(() => isTaskOpened(data), [data, isTaskOpened]);

  const addTask = () => {
    unselectNode();
    openAddCard(data);
  };

  return (
    <Fragment>
      <Fade in={started} appear={true} enter={true}>
        <PaperCard selected={selected} dragging={isDragging}>
          <Handle
            type="target"
            position={Position.Right}
            className={classes.handle}
          ></Handle>

          <Grid container>
            <Grid item xs={completed ? 10 : 12}>
              <Typography variant="body1">{title}</Typography>
            </Grid>

            {completed && (
              <Grid
                item
                container
                xs={2}
                className={classes.icon}
                justify="flex-end"
                alignItems="center"
              >
                <Icon fontSize="inherit">
                  <CheckCircle fontSize="inherit"></CheckCircle>
                </Icon>
              </Grid>
            )}
          </Grid>

          <Handle
            type="source"
            position={Position.Left}
            className={classes.handle}
          ></Handle>
        </PaperCard>
      </Fade>

      <Collapse
        in={menuOpen}
        unmountOnExit
        className={classes.menu}
        timeout={{ appear: 200, enter: 200, exit: 0 }}
      >
        <Paper className={classes.menuPaper} elevation={4}>
          <Grid container direction="column">
            <Grid item container>
              <div
                onClick={unselectNode}
                style={{ width: '100%', paddingTop: 8 }}
              >
                <ItemButton
                  label="Open"
                  onClick={() => openTodo(data.id)}
                  icon={<Launch></Launch>}
                ></ItemButton>
              </div>
            </Grid>

            <Grid item container>
              <NodeCompleteButton
                disabled={!canBeCompleted}
                todo={data}
              ></NodeCompleteButton>
            </Grid>

            <Grid item container>
              <AddTaskItemButton
                onClick={addTask}
                disabled={completed || isTaskOpen}
              ></AddTaskItemButton>
            </Grid>

            <Grid item container>
              <NodeArchiveButton todo={data}></NodeArchiveButton>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </Fragment>
  );
};

export default NodeCard;
