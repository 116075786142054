import TodoServices from '../TodoServices';

import addEdgeEmailService from './addEdgeEmailService';
import archiveTodoEmailService from './archiveTodoEmailService';
import clearEmailService from './clearEmailService';
import completeTodoEmailService from './completeTodoEmailService';
import createTodoChildEmailService from './createTodoChildEmailService';
import createTodoEmailService from './createTodoEmailService';
import deleteEdgeEmailService from './deleteEdgeEmailService';
import editTodoEmailService from './editTodoEmailService';
import listTodosEmailService from './listTodosEmailService';
import readTodoEmailService from './readTodoEmailService';

const EmailTodoServices = (): TodoServices => {
  return {
    addEdge: addEdgeEmailService,
    archive: archiveTodoEmailService,
    clear: clearEmailService,
    complete: completeTodoEmailService,
    create: createTodoEmailService,
    createChild: createTodoChildEmailService,
    deleteEdge: deleteEdgeEmailService,
    edit: editTodoEmailService,
    list: listTodosEmailService,
    read: readTodoEmailService,
  };
};

const emailTodoServices = EmailTodoServices();

export default emailTodoServices;
