import { FunctionComponent } from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      width: '100%',
      textDecoration: 'none',
      borderRadius: theme.spacing(1),
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    button: {
      textTransform: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  })
);

type Props = {
  icon: JSX.Element;
  route: string;
};
const LoginCardOption: FunctionComponent<Props> = ({ icon, route }) => {
  const classes = useStyles();

  return (
    <Link to={route} className={classes.link}>
      <Button fullWidth className={classes.button}>
        <span className={classes.icon}>{icon}</span>
        <Typography variant="body1">
          <b>Continue anonymously</b>
        </Typography>
      </Button>
    </Link>
  );
};

export default LoginCardOption;
