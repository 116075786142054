import { useEffect, useState } from 'react';

import LoadStatus, { DONE, ERROR, LOADING, SUBMIT } from 'config/LoadStatus';

import useAuthentication from 'pages/providers/authentication/useAuthentication';
import useUseCases from 'pages/providers/usecases/useUseCases';

import delayed from 'utils/delayed';

const useLogoutPage = () => {
  const { isAuthenticated, logout } = useAuthentication();
  const { todoUseCases } = useUseCases();
  const [status, setStatus] = useState<LoadStatus>(
    isAuthenticated ? SUBMIT : DONE
  );

  useEffect(() => {
    if (status !== SUBMIT || !todoUseCases) return;
    setStatus(LOADING);

    const exit = async () => {
      try {
        await todoUseCases.clear();
        await delayed(logout());

        setStatus(DONE);
      } catch (error) {
        setStatus(ERROR);
      }
    };
    exit();
  }, [status, todoUseCases, logout]);

  const retry = () => {
    if (status === SUBMIT || status === LOADING || status === DONE) return;
    setStatus(SUBMIT);
  };

  return {
    loading: status === LOADING,
    done: status === DONE,
    error: status === ERROR,
    retry,
  };
};

export default useLogoutPage;
