import TodoContent from 'domain/todo/types/TodoContent';
import TodoContentErrors from 'domain/todo/types/TodoContentErrors';

const validateTodoUseCase = (content: TodoContent): TodoContentErrors => {
  const errors: TodoContentErrors = {};

  const { title } = content;

  if (!title.trim().length) errors.title = 'Title cannot be empty';

  return errors;
};

export const hasErrors = (errors: TodoContentErrors): boolean =>
  Object.keys(errors).length > 0;

export default validateTodoUseCase;
