import { FunctionComponent, useMemo } from 'react';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';

import TodoInput from '../TodoInput';

type Props = {
  parent: Todo;
  onClose: () => void;
  onTodoCreated: (todo: Todo) => void;
  createTodoChild: (parent: Todo) => (content: TodoContent) => Promise<Todo>;
};
const TodoInputChild: FunctionComponent<Props> = ({
  parent,
  onClose,
  onTodoCreated,
  createTodoChild,
}) => {
  const createTodo = useMemo(
    () => createTodoChild(parent),
    [createTodoChild, parent]
  );

  return (
    <TodoInput
      onTodoCreated={onTodoCreated}
      onClose={onClose}
      createTodo={createTodo}
    ></TodoInput>
  );
};

export default TodoInputChild;
