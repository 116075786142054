import { Dashboard } from '@material-ui/icons';

import ItemBase from './ItemBase';

const DashboardItem = () => {
  return (
    <ItemBase
      text="Dashboard"
      icon={<Dashboard fontSize="inherit"></Dashboard>}
    ></ItemBase>
  );
};

export default DashboardItem;
