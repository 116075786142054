import { FunctionComponent } from 'react';
import {
  Fade,
  Grid,
  Grow,
  Typography,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import AppName from 'config/AppName';
import Routes from 'config/Routes';

import useScreenSize from 'utils/useScreenSize';

import LoginFormStep from './steps/LoginFormStep';
import LoginCodeStep from './steps/LoginCodeStep';
import LoginCardOptions from './LoginCardOptions';

import useLoginCard from './useLoginCard';

type StylesProps = {
  isMobile: boolean;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: '8px',
      padding: `${theme.spacing(6)}px 0`,
      border: (props: StylesProps) =>
        props.isMobile ? 'none' : `1px solid rgba(0, 0, 0, 0.16)`,
    },
    title: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(6)}px`,
      marginBottom: theme.spacing(8),
      color: 'rgba(0, 0, 0, 0.64)',
    },
    form: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(6)}px`,
    },
    code: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(5)}px`,
    },
    divider: {
      marginTop: (props: StylesProps) =>
        props.isMobile ? theme.spacing(3) : theme.spacing(6),
      marginBottom: (props: StylesProps) =>
        props.isMobile ? theme.spacing(3) : theme.spacing(6),
      color: 'rgba(0, 0, 0, 0.40)',
    },
    options: {
      padding: (props: StylesProps) =>
        props.isMobile ? 0 : `0 ${theme.spacing(6)}px`,
    },
    links: {
      marginTop: theme.spacing(10),
    },
    link: {
      textDecoration: 'none',
    },
    bold: {
      color: theme.palette.primary.light,
    },
  })
);

const LoginCard = () => {
  const {
    formStep,
    email,
    setEmail,
    validationError,
    submitForm,
    FormRequest,
    digits,
    setDigits,
    confirmCode,
    ConfirmCodeRequest,
    submitError,
  } = useLoginCard();

  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });

  const showForm = formStep;
  const showCodeConfirmation = !formStep;

  return (
    <Grow in={true}>
      <Grid container>
        <Container className={classes.root} isMobile={isMobile}>
          <Grid container direction="column">
            <Grid item container className={classes.title} justify="center">
              <Typography style={{ textAlign: 'center' }} variant="h6">
                <b>
                  Log in to <i className={classes.bold}>{AppName}</i>
                </b>
              </Typography>
            </Grid>

            <Fade
              in={showForm}
              unmountOnExit
              timeout={{ exit: 0, enter: 200, appear: 200 }}
            >
              <div className={classes.form}>
                <LoginFormStep
                  email={email}
                  setEmail={setEmail}
                  submitError={submitError}
                  validationError={validationError}
                  loading={FormRequest.loading}
                  submitForm={submitForm}
                ></LoginFormStep>
              </div>
            </Fade>

            <Fade
              in={showCodeConfirmation}
              unmountOnExit
              timeout={{ exit: 0, enter: 200, appear: 200 }}
            >
              <Grid item container justify="center" className={classes.code}>
                <LoginCodeStep
                  email={email}
                  digits={digits}
                  setDigits={setDigits}
                  loading={ConfirmCodeRequest.loading}
                  submit={confirmCode}
                  error={submitError}
                ></LoginCodeStep>
              </Grid>
            </Fade>

            <Grid item container direction="column" className={classes.options}>
              <Grid item container justify="center" className={classes.divider}>
                <Typography variant="body1">
                  <b>OR</b>
                </Typography>
              </Grid>

              <Grid item container>
                <LoginCardOptions></LoginCardOptions>
              </Grid>

              <Grid item container justify="center" className={classes.links}>
                <Link to={Routes.AUTH.REGISTER} className={classes.link}>
                  <Typography variant="body1" style={{ textAlign: 'center' }}>
                    <b>Register</b> an account
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grow>
  );
};

type ContainerProps = {
  isMobile: boolean;
  className: string;
};
const Container: FunctionComponent<ContainerProps> = ({
  children,
  isMobile,
  className,
}) => {
  if (isMobile) return <div className={className}>{children}</div>;

  return (
    <Paper elevation={4} className={className}>
      {children}
    </Paper>
  );
};

export default LoginCard;
