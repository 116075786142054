import Todo from 'domain/todo/types/Todo';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import TodoServices from 'services/todo/TodoServices';

const completeTodoUseCase =
  (todoServices: TodoServices) =>
  async (todo: Todo): Promise<TodoParentChild> => {
    const parentChild = await todoServices.complete(todo.id);

    return parentChild;
  };

export default completeTodoUseCase;
