import { useState, useEffect } from 'react';

import LoadStatus, { DONE, ERROR, LOADING } from 'config/LoadStatus';

import Todo from 'domain/todo/types/Todo';
import TodoContent from 'domain/todo/types/TodoContent';
import TodoContentErrors from 'domain/todo/types/TodoContentErrors';
import TodoContentValidationError from 'domain/todo/types/TodoContentValidationError';

import validateTodoUseCase from 'domain/todo/usecases/validateTodoUseCase';

import delayed from 'utils/delayed';

type Params = {
  initialContent: TodoContent;
  saveTodo: (todo: TodoContent) => Promise<Todo>;
  onTodoSaved: (todo: Todo) => void;
};
const useTodoForm = (params: Params) => {
  const { initialContent, saveTodo, onTodoSaved } = params;

  const [content, setContent] = useState<TodoContent>(initialContent);
  const [status, setStatus] = useState<LoadStatus>();
  const [errors, setErrors] = useState<TodoContentErrors>({});

  useEffect(() => {
    if (status !== LOADING) return;

    const create = async () => {
      try {
        const todo = await delayed(saveTodo(content), 50);

        setStatus(DONE);

        onTodoSaved(todo);
      } catch (error) {
        setStatus(ERROR);

        if (error instanceof TodoContentValidationError) {
          setErrors(error.errors);
          return;
        }
      }
    };
    create();
  }, [status, content, saveTodo, onTodoSaved]);

  const onSubmit = () => {
    if (status === LOADING) return;

    setStatus(LOADING);
  };

  const _setContent = (form: TodoContent) => {
    if (status === ERROR) {
      const errors = validateTodoUseCase(form);
      setErrors(errors);
    }

    setContent(form);
  };

  return { content, setContent: _setContent, onSubmit, errors };
};

export default useTodoForm;
