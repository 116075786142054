import { IsRootError, NotFoundError, ParentNotFoundError } from 'domain/errors';
import TodoParentChild from 'domain/todo/types/TodoParentChild';

import AnonymousStorage, { mapITodoToTodo } from './AnonymousStorage';

const deleteEdgeAnonymousService =
  (db: AnonymousStorage) =>
  async (todoID: string): Promise<TodoParentChild> => {
    const { iTodo, iParent } = await db.ref.transaction(
      'readwrite',
      db.todos,
      async () => {
        const id = parseInt(todoID);
        const iTodo = await db.todos.get(id);
        if (!iTodo) throw new NotFoundError();

        if (!iTodo.parentID) throw new IsRootError();
        const parentID = iTodo.parentID;
        const iParent = await db.todos.get(parentID);
        if (!iParent) throw new ParentNotFoundError();

        const updatedIParent = {
          ...iParent,
          childrenCount: (iParent.childrenCount || 0) - 1,
          completedChildren:
            (iParent.completedChildren || 0) - (iTodo.completed ? 1 : 0),
        };

        delete iTodo.parentID;

        await db.todos.put(iTodo, id);
        await db.todos.put(updatedIParent, parentID);

        return { iTodo, iParent: updatedIParent };
      }
    );

    return {
      parent: mapITodoToTodo(iParent),
      child: mapITodoToTodo(iTodo),
    };
  };

export default deleteEdgeAnonymousService;
