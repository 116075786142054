import TodoParentChild from 'domain/todo/types/TodoParentChild';

import api from 'services/api';

const deleteEdgeEmailService = async (
  todoID: string
): Promise<TodoParentChild> => {
  const todoParentChild = await api.todo.deleteEdge(todoID);
  return todoParentChild;
};

export default deleteEdgeEmailService;
