import { createContext, FC, useContext } from 'react';

type TodoFormDialogContextType = {
  router?: (todoID: string) => void;
};
const TodoFormDialogContext = createContext<TodoFormDialogContextType>({});

type Props = {
  router: (todoID: string) => void;
};
const TodoFormDialogProvider: FC<Props> = ({ children, router }) => {
  return (
    <TodoFormDialogContext.Provider
      value={{
        router,
      }}
    >
      {children}
    </TodoFormDialogContext.Provider>
  );
};

export const useTodoFormDialogContext = () => {
  const { router } = useContext(TodoFormDialogContext);

  return {
    router: router!,
  };
};

export default TodoFormDialogProvider;
