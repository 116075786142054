import { FunctionComponent } from 'react';
import { Paper, Grid, makeStyles, createStyles, Icon } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import TextTruncate from 'react-text-truncate';

import Todo from 'domain/todo/types/Todo';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 88,
      width: '100%',
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.64)',
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:hover': {
        backgroundColor: '#FFFFFF40',
      },
    },
    title: {
      width: '100%',
    },
    text: {
      fontSize: 14,
      width: '100%',
      lineHeight: 1.5,
      fontFamily: 'Inika, serif',
    },
    completedIcon: {
      fontSize: theme.spacing(2),
      color: theme.palette.primary.dark,
    },
  })
);

type Props = {
  todo: Todo;

  onClick?: () => void;
};
const TodoCard: FunctionComponent<Props> = ({ todo, onClick }) => {
  const classes = useStyles();

  const { content, completed } = todo;
  const { title } = content;

  return (
    <Paper
      className={classes.root}
      tabIndex={0}
      onClick={onClick}
      elevation={4}
    >
      <Grid
        container
        direction="column"
        style={{ height: '100%', width: '100%' }}
      >
        <Grid item container className={classes.title}>
          <TextTruncate
            line={2}
            element="b"
            truncateText=" ..."
            text={title}
            containerClassName={classes.text}
          ></TextTruncate>
        </Grid>

        <Grid item container style={{ flexGrow: 1 }}></Grid>

        {completed && (
          <Grid item container justify="flex-end">
            <Icon className={classes.completedIcon}>
              <CheckCircle fontSize="inherit"></CheckCircle>
            </Icon>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default TodoCard;
