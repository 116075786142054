import { Grid, Zoom, makeStyles, createStyles } from '@material-ui/core';
import { Link, Route, Switch } from 'react-router-dom';

import Routes from 'config/Routes';

import AppTitle from 'components/typography/AppTitle';
import NotFound from 'components/not_found/NotFound';

import LoginPage from './login/LoginPage';
import RegisterPage from './register/RegisterPage';

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      height: '100%',
      paddingTop: '5vh',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey[100],
    },
    title: {
      marginBottom: theme.spacing(5),
    },
    homeLink: {
      textDecoration: 'none',
    },
  })
);

const AuthPage = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.page}>
      <Grid item container xs={12} sm={7} md={4} lg={3}>
        <Grid item container direction="column">
          <Zoom in={true}>
            <Grid item container justify="center" className={classes.title}>
              <Link to={Routes.HOME} className={classes.homeLink}>
                <AppTitle></AppTitle>
              </Link>
            </Grid>
          </Zoom>

          <Grid item container>
            <Switch>
              <Route path={Routes.AUTH.LOGIN.ROOT}>
                <LoginPage></LoginPage>
              </Route>

              <Route path={Routes.AUTH.REGISTER}>
                <RegisterPage></RegisterPage>
              </Route>

              <NotFound></NotFound>
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthPage;
