import {
  Fade,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import FlatButton from 'components/buttons/FlatButton';
import AppTitle from 'components/typography/AppTitle';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: '5vh',
    },
    header: {
      marginTop: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

const UnauthenticatedWarning = () => {
  const history = useHistory();
  const classes = useStyles();

  const goToHome = () => history.push(Routes.HOME);

  return (
    <Fade in={true} unmountOnExit>
      <Grid container direction="column" justify="center">
        <Grid item container justify="center" className={classes.root}>
          <AppTitle></AppTitle>
        </Grid>

        <Grid item container justify="center" className={classes.header}>
          <Typography variant="h6">
            You are <b>not</b> authenticated
          </Typography>
        </Grid>

        <Grid item container justify="center" className={classes.button}>
          <FlatButton onClick={goToHome}>Go to Home Page</FlatButton>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default UnauthenticatedWarning;
