import { FunctionComponent } from 'react';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import { TextTransformProperty } from 'csstype';

type StyleProps = {
  textTransform: TextTransformProperty;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.grey[50],
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      textTransform: (props: StyleProps) => props.textTransform,
    },
    disabled: {
      backgroundColor: `${theme.palette.primary.light}24`,
    },
  })
);

type Props = {
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  endIcon?: JSX.Element;
  textTransform?: TextTransformProperty;
};
const RaisedButton: FunctionComponent<Props> = ({
  children,
  onClick = () => {},
  fullWidth = false,
  disabled = false,
  endIcon,
  textTransform = 'uppercase',
}) => {
  const classes = useStyles({ textTransform });

  return (
    <Button
      disabled={disabled}
      className={classes.root}
      onClick={onClick}
      fullWidth={fullWidth}
      endIcon={endIcon}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {children}
    </Button>
  );
};

export default RaisedButton;
