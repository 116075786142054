import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'config/Routes';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

const useLoginAnonymous = () => {
  const { loginAnonymous } = useAuthentication();
  const [name, setName] = useState('');
  const history = useHistory();

  const submit = async () => {
    await loginAnonymous(name);
    history.push(Routes.APP.TODO.MENU.ROOT);
  };

  return {
    name,
    setName,
    canLogin: name.trim().length > 0,
    submit,
  };
};

export default useLoginAnonymous;
