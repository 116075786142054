import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Routes from 'config/Routes';

import AppPage from 'pages/app/AppPage';
import AuthPage from 'pages/auth/AuthPage';
import HomePage from 'pages/home/HomePage';
import LogoutPage from 'pages/logout/LogoutPage';

import AuthenticationProvider from 'pages/providers/authentication/AuthenticationProvider';
import UseCasesProvider from 'pages/providers/usecases/UseCasesProvider';
import MainThemeProvider from 'theme/MainThemeProvider';

import api from 'services/api';

api.init();

const App = () => {
  return (
    <AuthenticationProvider>
      <UseCasesProvider>
        <MainThemeProvider>
          <BrowserRouter>
            <Switch>
              <Route path={Routes.AUTH.ROOT}>
                <AuthPage></AuthPage>
              </Route>

              <Route path={Routes.LOGOUT} exact>
                <LogoutPage></LogoutPage>
              </Route>

              <Route path={Routes.APP.ROOT}>
                <AppPage></AppPage>
              </Route>

              <Route path={Routes.HOME}>
                <HomePage></HomePage>
              </Route>
            </Switch>
          </BrowserRouter>
        </MainThemeProvider>
      </UseCasesProvider>
    </AuthenticationProvider>
  );
};

export default App;
