import { FunctionComponent } from 'react';
import { CircularProgress, makeStyles, createStyles } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import RaisedButton from './RaisedButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      color: theme.palette.grey[200],
    },
  })
);

type Props = {
  onClick: () => void;
  isLoading?: boolean;
  fullWidth?: boolean;
  showIcon?: boolean;
};
const SubmitButton: FunctionComponent<Props> = ({
  onClick,
  isLoading = false,
  fullWidth = false,
  showIcon = true,
  children,
}) => {
  const _icon = () => {
    if (!showIcon) return;

    if (isLoading) return <Progress size={20}></Progress>;

    return <Send fontSize="small"></Send>;
  };

  return (
    <RaisedButton onClick={onClick} fullWidth={fullWidth} endIcon={_icon()}>
      {!showIcon && isLoading && <Progress size={24}></Progress>}
      {(showIcon || !isLoading) && children}
    </RaisedButton>
  );
};

type ProgressProps = {
  size: number;
};
const Progress: FunctionComponent<ProgressProps> = ({ size }) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={classes.loading}
      size={size}
    ></CircularProgress>
  );
};

export default SubmitButton;
