import Session from 'domain/authentication/types/Session';

const KEY = 'session';

const SessionStorageService = {
  save: (session: Session) =>
    localStorage.setItem(KEY, JSON.stringify(session)),
  read: (): Session | null => {
    const savedSession = localStorage.getItem(KEY);

    if (!savedSession) return null;
    return JSON.parse(savedSession);
  },
  clear: () => localStorage.removeItem(KEY),
};

export default SessionStorageService;
