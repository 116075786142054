import ApiError from 'domain/errors/ApiError';
import { NotFoundError } from 'domain/errors';

import TodoParentChild from 'domain/todo/types/TodoParentChild';

import api from 'services/api';

const readTodoEmailService = async (id: string): Promise<TodoParentChild> => {
  try {
    const todoParentChild = await api.todo.read(id);
    return todoParentChild;
  } catch (error) {
    if (error instanceof ApiError) {
      const { message } = error;

      if (message === 'not_found_todo') throw new NotFoundError();
    }

    throw error;
  }
};

export default readTodoEmailService;
