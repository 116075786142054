import {
  Grid,
  AppBar,
  makeStyles,
  createStyles,
  Fade,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Routes from 'config/Routes';

import AppTitle from 'components/typography/AppTitle';

import useAuthentication from 'pages/providers/authentication/useAuthentication';

import AuthenticationButtons from './AuthenticationButtons';
import DashboardButton from './DashboardButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      backgroundColor: 'transparent',
    },
    homeLink: {
      textDecoration: 'none',
    },
    emptySpace: {
      flexGrow: 1,
    },
  })
);

const HomeHeader = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuthentication();

  return (
    <Fade in={true} unmountOnExit>
      <AppBar position="relative" className={classes.root} elevation={0}>
        <Grid container>
          <Grid item>
            <Link to={Routes.HOME} className={classes.homeLink}>
              <AppTitle></AppTitle>
            </Link>
          </Grid>

          <Grid item className={classes.emptySpace}></Grid>

          <Grid item>
            {isAuthenticated ? (
              <DashboardButton></DashboardButton>
            ) : (
              <AuthenticationButtons></AuthenticationButtons>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </Fade>
  );
};

export default HomeHeader;
