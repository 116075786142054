import { FC, Fragment, MouseEvent, useState } from 'react';
import {
  createStyles,
  Grid,
  Fade,
  makeStyles,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import NodeArchiveMenuBody from './NodeArchiveMenuBody';

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      position: 'absolute',
      zIndex: 1,
      width: 310,
      marginLeft: -10,
      minHeight: 120,
    },
  })
);

type Props = {
  archive: boolean;
  submit: () => void;
  render: (
    onClick: (event: MouseEvent<HTMLButtonElement>) => void
  ) => JSX.Element;
};
const NodeArchiveMenu: FC<Props> = ({ archive, submit, render }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>();
  const classes = useStyles();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);

  const onClose = () => setAnchor(null);

  const _submit = () => {
    submit();
    onClose();
  };

  return (
    <Fragment>
      {render(openMenu)}

      <Fade in={!!anchor} unmountOnExit>
        <Paper elevation={8} className={classes.menu}>
          <Grid container direction="column">
            <Grid item container justify="flex-end" style={{ padding: 8 }}>
              <IconButton size="small" onClick={onClose}>
                <Close fontSize="small"></Close>
              </IconButton>
            </Grid>

            <Grid item container>
              <NodeArchiveMenuBody
                archive={archive}
                submit={_submit}
              ></NodeArchiveMenuBody>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Fragment>
  );
};

export default NodeArchiveMenu;
