import ApiError from 'domain/errors/ApiError';

import LoginServices from 'services/authentication/LoginServices';

const LoginUseCases = {
  validate: (email: string) => {
    if (!email) throw new EmptyEmail();
  },
  sendCode: async (email: string): Promise<string> => {
    try {
      const token = await LoginServices.sendCode(email);
      return token;
    } catch (error) {
      if (error instanceof ApiError) {
        const { message } = error;

        if (message === 'invalid_email') throw new InvalidEmail();
        if (message === 'user_not_found') throw new UserNotFound();
      }

      throw error;
    }
  },
  confirmCode: (token: string) => async (code: string) => {
    try {
      return await LoginServices.confirmCode(token)(code);
    } catch (error) {
      if (error instanceof ApiError) {
        const { message } = error;

        if (message === 'wrong_code' || message === 'invalid_code')
          throw new WrongCode();
        if (message === 'invalid_code_token') throw new ExpiredCode();
      }

      throw error;
    }
  },
};

export class EmptyEmail {}

export class InvalidEmail {}
export class UserNotFound {}

export class WrongCode {}
export class ExpiredCode {}

export default LoginUseCases;
